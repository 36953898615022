export default class API {
    constructor(api) {
        this.api = api;
    }

    async fetchFromAPI(endpoint, taskId, task, method='get', data={}) {
        let receivedData = false;
        let errorResponse = false;

        try {
            const response = await this.api({
                method,
                url: `v1/${endpoint}`,
                data,
                onUploadProgress: (progress) => {
                    // console.log(progress);
                }
            });

            receivedData = response.data;


        } catch(error) {
            data = false;

            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                // Dispatch Error
                const { status, statusText = '', data = false } = error.response;

                errorResponse = {
                    status,
                    detail: statusText,
                    taskId,
                    task,
                    data
                };

            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                errorResponse = {
                    status: error.request.status,
                    detail: error.request.statusText,
                    taskId,
                    task,
                    data
                }
            } else {
                // Something happened in setting up the request that triggered an Error
                errorResponse = {
                    status: 500,
                    detail: 'A technical error occurred - please try again.',
                    taskId,
                    task,
                    data
                };
            }
        }

        const response = {data: receivedData, errors: errorResponse};


        return response;
    }


    /**
     * Gets list of prosthodontists, results are paged and will default to Page 1
     * @param api
     * @param page
     * @param filters - refer to API docs
     * @returns {Promise<*>}
     */
    async getProsthodontists(page, filters={}) {
        if (page !== 1) {
            filters['page'] = page
        }

        let queryString = Object.keys(filters).map(key => key + '=' + filters[key]).join('&');

        queryString =  queryString ? `?${queryString}` : '';

        const prosthodontists = await this.fetchFromAPI(
            `/members/${queryString}`,
            'get-prosthodontists',
            'Get Prosthodontists'
        );

        return prosthodontists;
    }


    /**
     * Gets the details of the current authenticated user
     * @param api
     * @returns {Promise<*>}
     */
    async getProsthodontistBySlug(slug) {
        const accountDetails = await this.fetchFromAPI(
            `members/${slug}/?slug=true`,
            'get-prosthodontist',
            'Get Prosthodontist'
        );

        return accountDetails;
    }

}