import API from "./api";

export const CREATE_NEW_UNIVERSITY = 'CREATE_NEW_UNIVERSITY';
export const NETWORK_REQUEST_IN_PROGRESS = 'NETWORK_REQUEST_IN_PROGRESS';
export const SET_CURRENT_UNIVERSITY = 'SET_CURRENT_UNIVERSITY';
export const RESET_UNIVERSITY_DID_UPDATE = 'RESET_UNIVERSITY_DID_UPDATE';

export const REQUEST_PAGINATED_UNIVERSITIES = 'REQUEST_PAGINATED_UNIVERSITIES';
export const RECEIVE_PAGINATED_UNIVERSITIES = 'RECEIVE_PAGINATED_UNIVERSITIES';
export const RECEIVE_UNIVERSITY_SUGGESTIONS = 'RECEIVE_UNIVERSITY_SUGGESTIONS';
export const CLEAR_UNIVERSITY_SUGGESTIONS = 'CLEAR_UNIVERSITY_SUGGESTIONS';
export const NETWORK_ERROR = 'NETWORK_ERROR';

export const RECEIVE_ACADEMICS = 'RECEIVE_ACADEMICS';

export const RECEIVE_INSTRUCTORS = 'RECEIVE_INSTRUCTORS';

export const RECEIVE_RESEARCH = 'RECEIVE_RESEARCH';

export const RECEIVE_EVENTS = 'RECEIVE_EVENTS';

const formatPostData = (data) => {
    const { results } = data;

    const posts = results.map(post => {
        const {
            id,
            status,
            slug,
            title,
            subtitle,
            content,
            featured_image: featuredImage,
            updated_on: lastUpdated,
            created_on: created,
            type: postType,
            meta
            } = post;
        return {
            id,
            status,
            slug,
            title,
            subtitle,
            content,
            featuredImage,
            created,
            lastUpdated,
            postType,
            meta
        }
    });

    return posts;
}

function setCurrentUniversity(data) {
    let universityName = '';

    const {
        id,
        slug,
        university_name: name,
        website,
        phone_number: phone,
        email,
        about,
        location,
        featured_image: featuredImage,
        students: students,
        partnered
    } = data;

    universityName = name ? name : data.name;

    return {
        type: SET_CURRENT_UNIVERSITY,
        id,
        slug,
        name: universityName,
        website,
        phone,
        email,
        about,
        location,
        featuredImage,
        students,
        partnered
    }
}

function resetUniversityDidUpdate() {
    return {
        type: RESET_UNIVERSITY_DID_UPDATE
    }
}

const createNewUniversity = (isUpdating = false) => ({
    type: CREATE_NEW_UNIVERSITY,
    isUpdating
});

const clearUniversitySuggestions = () => ({
    type: CLEAR_UNIVERSITY_SUGGESTIONS
})

function requestInProgress() {
    return {
        type: NETWORK_REQUEST_IN_PROGRESS
    }
}

function requestingUniversities() {
    return {
        type: REQUEST_PAGINATED_UNIVERSITIES
    }
}

function receivedUniversities(data) {
    const {count, current_page: currentPage, next, previous, results} = data;

    const universities = results.map(university => {
        const {
            id,
            slug,
            university_name: name,
            website,
            phone_number: phone,
            email,
            about,
            location,
            featured_image: featuredImage,
            students,
            partnered
            } = university;
        return {
            id,
            slug,
            name,
            website,
            phone,
            email,
            about,
            location,
            featuredImage,
            students,
            partnered
        }
    });

    return {
        type: RECEIVE_PAGINATED_UNIVERSITIES,
        count,
        currentPage,
        next,
        previous,
        data: universities
    }
}

function receiveAcademics(data) {
    return {
        type: RECEIVE_ACADEMICS,
        academics: formatPostData(data)
    }
}

function receiveInstructors(data) {
    return {
        type: RECEIVE_INSTRUCTORS,
        instructors: formatPostData(data)
    }
}

function receiveResearch(data) {
    return {
        type: RECEIVE_RESEARCH,
        research: formatPostData(data)
    }
}

function receiveEvents(data) {
    const {results} = data;

    const events = results.map(post => {
        const {
            id,
            status,
            slug,
            title,
            content,
            featured_image: featuredImage,
            cost,
            date,
            location,
            updated_on: lastUpdated,
            created_on: created,
            meta
            } = post;
        return {
            id,
            status,
            slug,
            title,
            content,
            featuredImage,
            cost,
            date,
            location,
            created,
            lastUpdated,
            meta
        }
    });

    return {
        type: RECEIVE_EVENTS,
        events
    }
}

function receiveUniversitySuggestions(data) {
    const results = data.results;

    results.push({title: 'None', id: null});

    return {
        type: RECEIVE_UNIVERSITY_SUGGESTIONS,
        results: results
    }
}

function networkError() {
    return {
        type: NETWORK_ERROR
    }
}

function delayedResetUniversityDidUpdate(ms) {
    return dispatch => {
        setTimeout(() => {
            dispatch(resetUniversityDidUpdate());
        }, (ms));
    }
}

function getUniversities(paginatedPage=1, filters={}) {
    return async(dispatch, getState, api) => {
        const {data: {universities: {universities: {isRequesting} } } } = getState();

        if (!isRequesting) {
            const dataAPI = new API(api);

            dispatch(requestingUniversities());

            const json = await dataAPI.getUniversities(paginatedPage, filters);

            return dispatch(receivedUniversities(json.data));
        }

        return Promise.resolve();
    }
}

function getUniversity(universityId, useSlug=false) {
    return async(dispatch, getState, api) => {
        const {data: {universities: {universities: {data} } } } = getState();

        // Reset current uni
        await dispatch(createNewUniversity(true));

        // Check loaded unis before making network request
        let university = data.find(uni => {
            if (useSlug) {
                return uni.slug === universityId;
            } else {
                return parseInt(uni.id) === parseInt(universityId);
            }
        });

        if (!university) {
            const dataAPI = new API(api);

            dispatch(requestInProgress());

            const json = await dataAPI.getUniversity(universityId, useSlug);

            if (useSlug && json.data.results.length > 0) {
                university =  json.data.results[0];
            } else {
                university = json.data;
            }
        }

        dispatch(setCurrentUniversity(university));

        return dispatch(delayedResetUniversityDidUpdate(2000));
    }
}

function getUniversitySuggestions(nameContains, exclude=null) {
    return async(dispatch, getState, api) => {
        const dataAPI = new API(api);

        let filter = {
            title__icontains: nameContains
        }

        if (exclude) filter['exclude'] = exclude;

        const json = await dataAPI.getUniversities(1, filter);

        return dispatch(receiveUniversitySuggestions(json.data));
    }
}

function getAcademics(universityId) {
    return async(dispatch, getState, api) => {
        const dataAPI = new API(api);
        const currentState = getState();

        const { data: { universities: { currentUniversity } } } = currentState;

        if (!currentUniversity.ready) {
            return Promise.resolve();
        }

        if (currentUniversity.fullTimeAcademics.length > 0) {
            return Promise.resolve();
        }

        dispatch(requestInProgress());

        const json = await dataAPI.getFullTimeAcademics(universityId);

        return dispatch(receiveAcademics(json.data));
    }
}

function getInstructors(universityId) {
    return async(dispatch, getState, api) => {
        const dataAPI = new API(api);
        const currentState = getState();

        const { data: { universities: { currentUniversity } } } = currentState;

        if (!currentUniversity.ready) {
            return Promise.resolve();
        }

        if (currentUniversity.visitingInstructors.length > 0) {
            return Promise.resolve();
        }

        dispatch(requestInProgress());

        const json = await dataAPI.getVisitingInstructors(universityId);

        return dispatch(receiveInstructors(json.data));
    }
}

function getResearch(universityId, pageSize=false) {
    return async(dispatch, getState, api) => {
        const dataAPI = new API(api);
        const currentState = getState();

        const { data: { universities: { currentUniversity } } } = currentState;

        if (!currentUniversity.ready) {
            return Promise.resolve();
        }

        if (currentUniversity.research.length > 0) {
            return Promise.resolve();
        }

        dispatch(requestInProgress());

        const json = await dataAPI.getCurrentResearch(universityId, pageSize);

        return dispatch(receiveResearch(json.data));
    }
}

function getEvents(universityId) {
    return async(dispatch, getState, api) => {
        const dataAPI = new API(api);
        const currentState = getState();

        const { data: { universities: { currentUniversity } } } = currentState;

        if (!currentUniversity.ready) {
            return Promise.resolve();
        }

        if (currentUniversity.events.length > 0) {
            return Promise.resolve();
        }

        dispatch(requestInProgress());

        const json = await dataAPI.getUniversityEvents(universityId);

        return dispatch(receiveEvents(json.data));
    }
}

export {
    getUniversities,
    getUniversity,
    createNewUniversity,
    getUniversitySuggestions,
    clearUniversitySuggestions,
    getAcademics,
    getInstructors,
    getResearch,
    getEvents
};
