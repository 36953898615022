import React, {Fragment, useEffect} from 'react';
import {Link} from 'react-router-dom';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Helmet } from "react-helmet";
import { FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import moment from 'moment';
import {FacebookShareButton, TwitterShareButton, LinkedinShareButton, EmailShareButton} from 'react-share';

import postEnhancer, {loadSinglePost} from '../postEnhancer';
import Section from '../../components/Section';
import DraftToHtml from '../../components/DraftToHtml';
import styles from './styles.scss';


const NewsSingle = (props) => {
    const {singlePost, posts, getSinglePost, getPosts, getLink, getExcerpt, getPostFeaturedImage, pageUrl} = props;

    const imageSizes = `(min-width: 1200px) 633px,
            (min-width: 992px) 533px, 
            (min-width: 768px) 480px, 
            (min-width: 576px) 540px ,100vw`;

    const publishDate = moment(singlePost.created);

    useEffect(() => {
        getSinglePost('news');
        getPosts('news', 1, {page_size: 3});
    },[]);

    const getHelmet = () => {
        const pageTitle = singlePost.title;
        return (
            <Helmet>
                <title>{pageTitle}</title>
            </Helmet>
        )
    };

    const renderRecentNews = () => {
        const newsPosts = 'news' in posts.types ? posts.types.news.data : [];

        return newsPosts.slice(0, 3).map(newsItem => {
            return (
                <li key={newsItem.id}>
                    <span className="date">{moment(newsItem.created).format('DD/MM/YYYY')}</span>
                    <h6 className="title">{newsItem.title}</h6>
                    <p className="excerpt">
                        {getExcerpt(singlePost, 60)}
                    </p>
                    <Link to={getLink(newsItem)} className="more">More <FontAwesomeIcon icon={['far', 'long-arrow-right']}/></Link>
                </li>
            );
        });
    };

    return (
        <Fragment>
            {getHelmet()}
            <Section styleName="news" tag="section" wrap={true} isTop={true}>
                <div styleName="news__content">
                    <div styleName="news__image">
                        {getPostFeaturedImage(singlePost, imageSizes, 'hero')}
                    </div>
                    <div styleName="news__header">
                        <span>
                            {publishDate.format('MMMM Do YYYY')}
                        </span>
                        <ul styleName="news__social-share" className="list-inline">
                            <li className="list-inline-item">
                                <FacebookShareButton url={pageUrl}>
                                    <FontAwesomeIcon icon={['fab', 'facebook']}/>
                                </FacebookShareButton>
                            </li>
                            <li className="list-inline-item">
                                <TwitterShareButton url={pageUrl}>
                                    <FontAwesomeIcon icon={['fab', 'twitter']}/>
                                </TwitterShareButton>
                            </li>
                            <li className="list-inline-item">
                                <LinkedinShareButton url={pageUrl}>
                                    <FontAwesomeIcon icon={['fab', 'linkedin']}/>
                                </LinkedinShareButton>
                            </li>
                            <li className="list-inline-item">
                                <EmailShareButton url={pageUrl}>
                                    <FontAwesomeIcon icon={['far', 'envelope']}/>
                                </EmailShareButton>
                            </li>
                        </ul>
                    </div>
                    <div styleName="news__body">
                        {singlePost.universityName
                            && <h3 styleName="university_name">{singlePost.universityName}</h3>
                        }
                        <h1 styleName="news__title">{singlePost.title}</h1>
                        {singlePost.content && <DraftToHtml rawContentState={singlePost.content}/>}
                    </div>
                </div>
                <div styleName="news__sidebar">
                    <div styleName="news__recent">
                        <h4 className="text-center">AANZP Recent News</h4>
                        <ul className="list-unstyled">
                            {renderRecentNews()}
                        </ul>
                    </div>
                </div>
            </Section>
        </Fragment>
    );
};

const EnhancedNewsSingle = postEnhancer(withStyles(styles)(NewsSingle));

export default {
    loadData: loadSinglePost('news'),
    component: EnhancedNewsSingle
};