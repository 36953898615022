import React from 'react';
// import { Link } from 'react-router-dom';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import {Helmet} from "react-helmet";

import Navbar from './components/Navbar';
import SecondaryNav from './components/SecondaryNav';
import bootstrap from '../../../scss/vendor/bootstrap.scss';
import ahamoReset from '../../../scss/vendor/ahamo-reset.scss';
import pageTransitions from '../../../scss/vendor/ahamo-page-transitions.scss';
import slickStyles from '../../../scss/vendor/slick.scss';


const Header = () => {
	return (
		<div>
			<Helmet titleTemplate="%s | Academy of Australian & New Zealand Prosthodontists" />
			<SecondaryNav/>
			<Navbar />
		</div>
	);
};

export default withStyles(bootstrap, ahamoReset, pageTransitions, slickStyles)(Header);
