import React, {Fragment} from 'react';
import {Link} from 'react-router-dom';
import withStyles from "isomorphic-style-loader/lib/withStyles";
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import * as yup from 'yup';
import { Row, Col} from 'reactstrap';

import styles from './styles.scss';
import Form from '../../../../components/Forms';


const Contact = (props) => {

    const onSubmit = (values, setSubmitting) => {
        const data = {
            first_name: values.firstName,
            last_name: values.lastName,
            enquiry_from: values.email,
            type: 'contact enquiry',
            subject: values.subject,
            message: values.message,
        };

        props.submitContactForm('contact', data, setSubmitting);
    };

    const validationSchema = {
        firstName: yup.string().required('Please provide your first name'),
        lastName: yup.string().required('Please provide your last name'),
        subject: yup.string().required('Please select a subject'),
        email: yup.string().email('Please provide a valid email').required('Please provide your email'),
        message: yup.string().required('Please provide details'),
    };

    return (
        <Col xs="12" md="9">
            <Row>
                <Col xs="12" md="8">
                    <p>{props.content}</p>
                    <Form
                        type="contact"
                        onSubmit={onSubmit}
                        submissionData={props.submissionData}
                        validationSchema={validationSchema}
                    />
                </Col>
                <Col xs="12" md="4">
                    <div styleName="find-a-prosthodontist">
                        <h2>Find a Prosthodontist</h2>
                        <Link styleName="cta" to="/find-a-prosthodontist">
                            <p>
                                Find a specialist prosthodontist near you
                            </p>
                            <FontAwesomeIcon styleName="icon-arrow" icon={['far', 'long-arrow-right']}/>
                        </Link>
                    </div>
                    <div styleName="emails">
                        <dl className="row">
                            <dt className="col-2">
                                <FontAwesomeIcon icon={['fas', 'envelope']}/>
                            </dt>
                            <dd className="col-10">
                                <a href="mailto:admin@aanzp.com.au">General Enquiries</a>
                            </dd>

                            <dt className="col-2">
                                <FontAwesomeIcon icon={['fas', 'users']}/>
                            </dt>
                            <dd className="col-10">
                                <a href="mailto:admin@aanzp.com.au">Membership Enquiries</a>
                            </dd>
                        </dl>
                    </div>
                    <div styleName="newsletter-cta">
                        Newsletter Signup <FontAwesomeIcon
                        styleName="icon-arrow"
                        icon={['far', 'long-arrow-right']}
                        />
                    </div>
                </Col>
            </Row>
        </Col>
    )
};

Contact.propTypes = {
    content: PropTypes.string
};

Contact.defaultProps = {
    content: `AANZP is happy to take any Institutional, government and media enquiries. 
    If you are in need of prosthodontic work or advice, please contact your local prosthodontist.`
}

export default withStyles(styles)(Contact);
