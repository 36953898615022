import API from "./api";

export const REQUEST_STATS = 'REQUEST_STATS';
export const RECEIVED_STATS = 'RECEIVED_STATS';

const requestStats = () => {
	return {
		type: REQUEST_STATS
	}
};

const receivedStats = (data) => {
	return {
		type: RECEIVED_STATS,
		memberCount: data.member_count,
		studentMemberCount: data.student_member_count,
		partneredUniversityCount: data.partnered_university_count
	}
};

const getStats = () => {
	return async(dispatch, getState, api) => {
		const dataAPI = new API(api);
		const currentState = getState();
		const { data: { stats } } = currentState;

		if (!stats.loaded) {

			dispatch(requestStats());

			const json = await dataAPI.getStats();

			return dispatch(receivedStats(json.data));
		}

		return Promise.resolve();
	}
};

export { getStats };