import baseApi from '../api';

export default class pageAPI extends baseApi {
    async getPosts(page=1, filters = {}) {
        if (page !== 1) {
            filters['page'] = page
        }

        let queryString = Object.keys(filters).map(key => key + '=' + filters[key]).join('&');

        queryString = queryString ? `?${queryString}` : '';

        const pages = await this.fetchFromAPI(
            `/posts/${queryString}`,
            'get-posts',
            'Get list of Posts',
            'get'
        );

        return pages;
    }


    async getPost(postId, useSlug=false) {
        if (useSlug) return await this.getPosts(1, {slug: postId})

        const event = await this.fetchFromAPI(
            `/posts/${postId}/`,
            'get-post',
            'Get Single Post',
            'get'
        );

        return event;
    }
}