import React, {useEffect} from 'react';
import withStyles from "isomorphic-style-loader/lib/withStyles";
import moment from 'moment';

import eventEnhancer from '../../../../events/eventEnhancer';
import CardEvent from '../../../../components/CardEvent';
import styles from './styles.scss';

const EventFeed = (props) => {
    const {events, getEvents, getExcerpt, getFeaturedImage, getLink} = props;

    useEffect(() => {
        getEvents(1, {university__isnull: true});
    }, []);

    const renderEvents = () => {
        return events.data.map(event => {
            const eventDate = moment(event.date);
            const day = eventDate.format('DD');
            const month = eventDate.format('MMM');
            const year = eventDate.format('YYYY');
            const imageSizes = `(min-width: 1200px) 338px,
            (min-width: 992px) 278px, 
            (min-width: 768px) 358px, 
            (min-width: 576px) 378px ,100vw`;

            return (
                <div key={event.id} styleName="event-feed__event">
                    <CardEvent
                        title={event.title}
                        img={getFeaturedImage(event, imageSizes)}
                        day={day}
                        month={month}
                        year={year}
                        link={getLink(event)}
                        theme="alt"
                    >
                        {getExcerpt(event)}
                    </CardEvent>
                </div>
            )
        });
    };

    return (
        <div styleName="event-feed">
            {renderEvents()}
        </div>
    );
};

export default eventEnhancer(withStyles(styles)(EventFeed));