import React from 'react';
import withStyles from "isomorphic-style-loader/lib/withStyles";
import PropTypes from 'prop-types';
import {Col, Row} from 'reactstrap';

import Section from '../../../../components/Section';
import Card from '../../../../components/CardStandard';
import styles from './styles.scss';


const Universities = (props) => {
    const renderUniversities = (countryCode) => {
			return props.universities.filter((university) => {
				const { location } = university;

				if (location) {
					return university.partnered && location.country === countryCode;
				}

				return false;
			}).map((university) => {
				let featuredImage = '';

				if (university.featuredImage) {
					const medLarge = university.featuredImage.sizes.find((size) => {
						return size.size === 'card_medium';
					});

					featuredImage = <img src={medLarge.url} alt={university.name} />
				}

				return (
                    <Col key={university.id} styleName="university" xs="6" lg="4">
                        <Card
							img={featuredImage}
							title={university.name}
							unstyled={true}
							link={`/universities/${university.slug}`}
							showMore={false}
						/>
                    </Col>
				)
			});
		};

    return (
        <Section styleName="universities-section">
            <Col xs="12" md={{size: 9, offset: 3}}>
                <h3 styleName="heading">Australia</h3>
                <Row styleName="universities">
                    {renderUniversities('AU')}
                </Row>
                <h3 styleName="heading">New Zealand</h3>
                <Row styleName="universities">
                    {renderUniversities('NZ')}
                </Row>
            </Col>
        </Section>
    );
}

Universities.propTypes = {
    universities: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        name: PropTypes.string,
        featuredImage: PropTypes.object,
        slug: PropTypes.string,
        location: PropTypes.object
    })),
};

Universities.defaultProps = {
	universities: []
};

export default withStyles(styles)(Universities);