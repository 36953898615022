import {
    CREATE_NEW_EVENT,
    NETWORK_REQUEST_IN_PROGRESS,
    SET_CURRENT_EVENT,
    RESET_EVENT_DID_UPDATE,
    REQUEST_PAGINATED_EVENTS,
    RECEIVE_PAGINATED_EVENTS,
    RECEIVE_EVENT_SUGGESTIONS,
    CLEAR_EVENT_SUGGESTIONS,
    NETWORK_ERROR
} from './actions';


const initialState = {
    isUpdating: false,
    currentEvent: {
        didUpdate: false,
        id: '',
        status: false,
        slug: '',
        created: false,
        lastUpdated: false,
        title: '',
        content: '',
        featuredImage: false,
        cost: 0,
        date: '',
        location: {
            street: '',
            suburb: '',
            state: '',
            country: '',
            postcode: ''
        },
        universityName: '',
        meta: []
    },
    events: {
        isRequesting: false,
        count: 0,
        currentPage: 0,
        next: false,
        previous: false,
        data: []
    },
    autoSuggest: [{title: 'None', id: null}]

}

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_NEW_EVENT:
            return Object.assign({}, state, {
               ...state,
                isUpdating: action.isUpdating,
                currentEvent: initialState.currentEvent
            });
        case NETWORK_REQUEST_IN_PROGRESS:
            return Object.assign({}, state, {
                ...state,
                isUpdating: true
            });
        case SET_CURRENT_EVENT:
            return Object.assign({}, state, {
                ...state,
                isUpdating: false,
                currentEvent: {
                    didUpdate: true,
                    id: action.id,
                    status: action.status,
                    slug: action.slug,
                    created: action.created,
                    lastUpdated: action.lastUpdated,
                    title: action.title,
                    content: action.content,
                    featuredImage: action.featuredImage,
                    cost: action.cost,
                    date: action.date,
                    location: action.location,
                    universityName: action.universityName,
                    meta: action.meta,
                },
            });
        case RESET_EVENT_DID_UPDATE:
            return Object.assign({}, state, {
               ...state,
               isUpdating: false,
               currentEvent: {
                   ...state.currentEvent,
                   didUpdate: false
               }
            });
        case REQUEST_PAGINATED_EVENTS:
            return Object.assign({}, state, {
                ...state,
                events: {
                    ...state.events,
                    isRequesting: true,
                }
            });
        case RECEIVE_PAGINATED_EVENTS:
            return Object.assign({}, state, {
                ...state,
                events: {
                    isRequesting: false,
                    count: action.count,
                    currentPage: action.currentPage,
                    next: action.next,
                    previous: action.previous,
                    data: action.data
                }
            });
        case RECEIVE_EVENT_SUGGESTIONS:
            return Object.assign({}, state, {
                ...state,
                autoSuggest: action.results
            });
        case CLEAR_EVENT_SUGGESTIONS:
            return Object.assign({}, state, {
                ...state,
                autoSuggest: []
            });
        case NETWORK_ERROR:
            return Object.assign({}, state, {
                ...state,
                events: {
                    ...state.events,
                    isRequesting: false,
                }
            });
        default:
            return state
    }
}

