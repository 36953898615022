import React, {Fragment, useState, useEffect} from 'react';
import withStyles from "isomorphic-style-loader/lib/withStyles";
import PropTypes from 'prop-types';
import SubNavigation from '../../../../components/SubNavigation';
import { Row, Col, Card, CardImg, CardBody } from 'reactstrap';

import styles from './styles.scss';
import postEnhnacer from '../../../../posts/postEnhancer';


const boardStructure = [
    {slug: 'executive', committee: 'Executive Committee', members: []},
    {slug: 'state-representatives', committee: 'State Representatives', members: []},
    {slug: 'public-relations', committee: 'Public Relations Committee', members: []},
    {slug: 'standards', committee: 'Standards Committee', members: []},
    {slug: 'examination-curriculum', committee: 'Examination and Curriculum Committee', members: []},
    {slug: 'website', committee: 'Website Committee', members: []},
    {slug: 'scientific', committee: 'Scientific Committee', members: []},
    {slug: 'constitutional', committee: 'Constitutional Committee', members: []},
    {slug: 'governance-officer', committee: 'Governance Officer', members: []},
];


const Board = (props) => {
    const {posts, getPosts, getPostFeaturedImage, getPostField} = props;

    const [aanzpBoard, setAanzpBoard] = useState(boardStructure);

    const [currentTier, setCurrentTier] = useState(0);

    const changeTier = (slug) => {
        const index = boardStructure.findIndex(element => {
            return element.slug === slug
        });

        setCurrentTier(index ? index : 0);
    };

    const links = boardStructure.map((item, index) => {
        return {
            id: item.slug,
            title: item.committee,
            slug: item.slug,
            active: index === currentTier
        }
    });
    
    const buildBoard = (members) => {
        const board = aanzpBoard;
        const imageSizes = `(min-width: 1200px) 338px,
            (min-width: 992px) 278px, 
            (min-width: 768px) 358px, 
            (min-width: 576px) 378px ,100vw`;
        
        for (let member of members) {
            const {id, title: name, subtitle: position} = member;
            const profileImage = getPostFeaturedImage(member, imageSizes, 'uncropped');
            const committeeMeta = getPostField(member, 'committee');

            const boardMember = {
                id,
                name,
                position,
                profileImage,
                committee: committeeMeta ? committeeMeta['meta_value'] : ''
            };

            // console.log(boardMember);

            if (boardMember.committee) {
                const committee = board.findIndex(element => {
                    return element.slug === boardMember.committee;
                });

                if (committee !== -1) {
                    // board[committee].members.push(boardMember);
                    const exists = board[committee].members.find(member => {
                        return member.id === id;
                    });

                    if (!exists) {
                        board[committee].members.push(boardMember);
                    }
                }
            }
        }

        setAanzpBoard(board);
    }

    useEffect(() => {
        props.getPosts('board-members', 1, {page_size: 100})
    }, []);

    useEffect(() => {
        const boardMembers = 'board-members' in posts.types ? posts.types['board-members'].data : [];
        buildBoard(boardMembers);
    }, [posts]);

    const renderTierMembers = tierMembers => {

        return tierMembers.map( member => {
            return (
                <Col key={member.id} styleName="member-item" xs="12" md="6">
                    <Card styleName="card">
                        <div styleName="card-image">
                            <div>
                                {member.profileImage}
                            </div>
                        </div>
                        <CardBody styleName="card-body">
                            <h5 styleName="position">{member.position}</h5>
                            <h4 styleName="name">{member.name}</h4>
                        </CardBody>
                    </Card>
                </Col>
            )
        });
    };

    return (
        <Col xs="12" md="9">
            <Row>
                <Col xs="12" md="8">
                    <h2 styleName="committee-title">{aanzpBoard[currentTier].committee}</h2>
                </Col>
            </Row>
            <Row>
                <Col xs="12" md="8">
                    <Row>
                        {renderTierMembers(aanzpBoard[currentTier].members)}
                    </Row>
                </Col>
                <Col xs="12" md="4">
                    <SubNavigation navigationItems={links} position='right' filterContent={changeTier}/>
                </Col>
            </Row>
        </Col>
    )
};


Board.propTypes = {
    board: PropTypes.arrayOf(PropTypes.shape({
        tierName: PropTypes.string,
        tierDescription: PropTypes.string,
        tierMembers: PropTypes.arrayOf(PropTypes.shape({
            firstName: PropTypes.string,
            lastName: PropTypes.string,
            position: PropTypes.string,
            image: PropTypes.string
        }))
    })),
}

export default postEnhnacer(withStyles(styles)(Board));