const carouselSettings = {
    arrows: false,
    dots: false,
    slidesToShow: 3,
    infinite: false,
    responsive: [
        {
            breakpoint:  991,
            settings: {
                centerMode: true,
                slidesToShow: 1,
                centerPadding: '150px',
                infinite: true
            }
        },
        {
          breakpoint:  767,
          settings: {
              centerPadding: '50px',
              centerMode: true,
              slidesToShow: 1,
              infinite: true
          }
        },
        {
            breakpoint: 575,
            settings: {
                slidesToShow: 1,
                centerMode: true,
                centerPadding: '30px',
                infinite: true
            }
        }
    ]
}

export default carouselSettings;