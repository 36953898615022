import {
    REQUESTING_PAGE,
    RECEIVED_PAGE,
    SAVE_PAGE_TO_STATE

} from './actions';

const initialState = {
    loadedPages: [],
    currentPage: {
        loaded: false,
        id: '',
        status: false,
        slug: '',
        created: false,
        lastUpdated: false,
        title: '',
        subtitle: '',
        content: '',
        prelude: '',
        parent: null,
        parentVerbose: '',
        children: [],
        isHome: false,
        heroImage: null,
        featuredImage: false,
        meta: []
    }
}

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case REQUESTING_PAGE:
            return Object.assign({}, state, {
                ...state,
                currentPage: initialState.currentPage
            });
        case RECEIVED_PAGE:
            return Object.assign({}, state, {
                ...state,
                currentPage: action.page
            });
        case SAVE_PAGE_TO_STATE: {
            return Object.assign({}, state, {
                ...state,
                loadedPages: action.loadedPages
            })
        }
        default:
            return state;
    }
}