import React, {Component, Fragment} from 'react';
import { connect } from 'react-redux';
import {Picture} from 'react-responsive-picture';
import {convertFromRaw} from 'draft-js';

import {getPosts, getPost} from '../data/posts/actions'


const postEnhancer = (WrappedComponent) => {
    class Post extends Component {
        constructor(props) {
            super(props);

            this.getSinglePost = this._getSinglePost.bind(this);

            this.pageUrl = this.props.location
                ? `https://www.aanzp.com.au${this.props.location.pathname}`
                : 'https://www.aanzp.com.au';
        }

        _getSinglePost(type, slug='') {
            const postSlug = slug ? slug : this.props.match.params.postSlug;

            this.props.getPost(postSlug, type, true);
        }

        getImage(event, size, returnObject=true) {
            const image = event ? event.featuredImage : false;

            if (image) {
                const imageItem = image.sizes.find(image => {
                    return image.size === size;
                });

                return returnObject ? imageItem : imageItem.url;
            }

            return returnObject ? {} : '';
        }


        getFeaturedImage(post, sizes, imageSize='card') {
            const featuredImage = post ? post.featuredImage : false;

            if (featuredImage) {
                const sources = featuredImage.sizes.filter(image => {
                    if (imageSize === 'uncropped') {
                        return image.size === 'medium' || image.size === 'medium_large' || image.size === 'large'
                    }

                    return image.size.startsWith(imageSize);
                }).map(image => (`${image.url} ${image.width}w`));

                const imgSrc = sources.join(', ');

                return <Picture src={imgSrc} alt={featuredImage.alt} sizes={sizes}/>
            }
        }

        getExcerpt(post, length=130) {
            if (post.content) {
                const contentState = convertFromRaw(JSON.parse(post.content));
                const plainText = contentState.getPlainText('').replace(/(\r\n|\n|\r)/gm, " ");;

                let trimmed = plainText.substring(0, length);
                trimmed = trimmed.substr(0, Math.min(trimmed.length, trimmed.lastIndexOf(" ")));

                return `${trimmed}...`;
            }
        }

        getField(post, fieldName) {
            return post.meta.find(metaItem => {
                return metaItem['meta_key'] === fieldName
            })
        }

        getLink(post) {
            return `/${post.postType}/${post.slug}/`;
        }

        render() {
            return (
                <WrappedComponent
                    singlePost={this.props.post}
                    pageUrl={this.pageUrl}
                    posts={this.props.posts}
                    getSinglePost={this.getSinglePost}
                    getPosts={this.props.getPosts}
                    getPostFeaturedImage={this.getFeaturedImage}
                    getImage={this.getImage}
                    getExcerpt={this.getExcerpt}
                    getLink={this.getLink}
                    getPostField={this.getField}
                    {...this.props}
                />
            )
        }
    }

    return connect(mapStateToProps, mapDispatchToProps)(Post);
};

const mapStateToProps = (state, ownProps) => {
    const {data: {posts: {currentPost: post, posts} } } = state;

    return {
        post,
        posts
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getPosts: (postType, page=1, filters={}) => dispatch(getPosts(postType, page, filters)),
        getPost: (postId, type, useSlug=false) => dispatch(getPost(postId, type, useSlug)),
    }
}

export const loadSinglePost = (type, slug='') => {
    return (store, pathKeys, matchPath) => {
        const postSlug = slug ? slug : pathKeys.postSlug;

        return store.dispatch(getPost(postSlug, type, true));
    }
}

export const loadPosts = (postType, page=1, filters={}) => {
    return (store) => {
        return store.dispatch(getPosts(postType, page, filters));
    }
}

export default postEnhancer;