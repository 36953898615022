import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import {Card, CardBody, Button, Row, Col} from "reactstrap";
import {Link, Element, Events, animateScroll as scroll, scrollSpy, scroller} from 'react-scroll';
import ReactCountryFlag from "react-country-flag";
import moment from 'moment';

import Hero from '../../components/Hero';
import Section from '../../components/Section';

import styles from './styles.scss';
import heroImage from './profile-cover.jpg';
import EducationItem from './components/EducationItem';
import Clinic from './components/Clinic';
import Form from '../../components/Forms';

import {getProsthodontist} from '../../data/prosthodontists/actions';
import { submitEnquiryForm } from "../../data/forms/actions";
import * as yup from "yup";

class PractitionerSingle extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const {match: {params: {prosthodontistSlug}}} = this.props;

        this.props.getProsthodontist(prosthodontistSlug);
        this.onEnquirySubmit = this._onEnquirySubmit.bind(this);
    }

    renderEducation() {
        const {prosthodontist: {education}} = this.props;

        if (Array.isArray(education)) {
            return education.map(item => {
                const {id, university_name: universityName, course, year_of_completion: completed} = item;
                return (
                    <EducationItem
                        key={id}
                        universityName={universityName}
                        universityCourse={course}
                        completionYear={completed}
                    />
                );
            });
        }

        return '';
    }

    _onEnquirySubmit(values, setSubmitting) {
        const data = {
            first_name: values.firstName,
            last_name: values.lastName,
            enquiry_from: values.email,
            type: 'prosthodontist',
            subject: values.subject,
            message: values.message,
            clinic: parseInt(values.clinic),
            member: parseInt(this.props.prosthodontist.id)
        };

        this.props.submitEnquiryForm('prosthodontist-enquiry', data, setSubmitting);
    }

    renderServicingLocations() {
        const {prosthodontist: {clinics}} = this.props;
        const locations = [];
        let countryIcon = '';

        if (Array.isArray(clinics)) {

            clinics.forEach(clinic => {
                const {
                    location: {
                        suburb,
                        country_verbose: country
                    }
                } = clinic;

                countryIcon = country.toLowerCase();

                locations.push(suburb);
            });

            if (countryIcon === 'australia') {
                countryIcon = 'au';
            } else if (countryIcon === 'new zealand') {
                countryIcon = 'nz';
            } else {
                countryIcon = '';
            }
        }

        return (
            <span>
                {countryIcon && <ReactCountryFlag code={countryIcon}/>} {locations.join(', ')}
            </span>
        );
    }

    renderClinics() {
        const {prosthodontist: {clinics}} = this.props;

        if (Array.isArray(clinics)) {
            return clinics.map(clinic => {
                const {
                    id,
                    name,
                    phone_number: phoneNumber,
                    website,
                    email,
                    location: {
                        street,
                        suburb,
                        city,
                        state_verbose: state,
                        country_verbose: country,
                        postcode,
                        latitude,
                        longitude
                    }
                } = clinic;

                const geo = {
                    lat: latitude ? parseFloat(latitude) : null,
                    lng: longitude ? parseFloat(longitude) : null
                }

                return (
                    <Clinic
                        key={id}
                        clinicName={name}
                        centerMap={geo}
                        mapZoom={16}
                        address={{
                            street,
                            suburb,
                            city,
                            state,
                            country,
                            postcode
                        }}
                        phone={phoneNumber}
                        website={website}
                        email={email}
                    />
                )
            });
        }

        return '';
    }

    render() {
        const initialValues = {
            firstName: '',
            lastName: '',
            subject: `Enquiry for ${this.props.prosthodontist.firstName} through AANZP: ${moment().format("MMMM Do YYYY")}`,
            clinic: '',
            email: '',
            message: '',
        };

        const validationSchema = {
            firstName: yup.string().required('Please provide your first name'),
            lastName: yup.string().required('Please provide your last name'),
            clinic: yup.string().required('Please Select a Clinic'),
            email: yup.string().email('Please provide a valid email').required('Please provide your email'),
            message: yup.string().required('Please provide details'),
        };

        return (
            <Fragment>
                <Hero backgroundImage={heroImage} small={true} backgroundOpacity={0.5} curveFill='#eef1f4'
                      simple={true}>
                    <h1 styleName="hero-title">
                        {this.props.prosthodontist.firstName} {this.props.prosthodontist.lastName}
                    </h1>
                </Hero>
                <Section
                    styleName="practitioner-wrap"
                >
                    <div styleName="practitioner">
                        <div styleName="practitioner__cover"/>
                        <div styleName="practitioner__profile">
                            <div styleName="practitioner__profile-pic">
                                {this.props.prosthodontist.profileImage
                                    ? <img
                                        src={this.props.prosthodontist.profileImage}
                                        alt={`AANZP - ${this.props.prosthodontist.firstName}
                                        ${this.props.prosthodontist.lastName}`}/>
                                    : <div styleName="img-placeholder">
                                        <FontAwesomeIcon icon={['fas', 'user']}/></div>
                                }
                            </div>
                            <div styleName="practitioner__profile-bio">
                                <Card styleName="practitioner__profile-card">
                                    <CardBody>
                                        <h1 styleName="practitioner__name">
                                            {this.props.prosthodontist.firstName} {this.props.prosthodontist.lastName}
                                        </h1>
                                        <dl styleName="practitioner__details" className="row">
                                            <dt className="col-sm-3">Servicing Areas:</dt>
                                            <dd className="col-sm-9">{this.renderServicingLocations()}</dd>
                                        </dl>
                                        <div styleName="inline-button-group">
                                            <Link className="btn btn-primary" to="contact" smooth={true} duration={500}>
                                                Make an Enquiry
                                            </Link>
                                            <Link className="btn btn-primary" to="clinics" smooth={true} duration={500}>
                                                Locations
                                            </Link>
                                        </div>
                                        <hr styleName="thick"/>
                                        <p styleName="practitioner__about">
                                            {this.props.prosthodontist.biography}
                                        </p>
                                    </CardBody>
                                </Card>
                                {this.props.prosthodontist.education &&
                                <Card styleName="practitioner__profile-card">
                                    <CardBody>
                                        <h3 styleName="practitioner__profile-title">Qualification and Education</h3>
                                        {this.renderEducation()}
                                    </CardBody>
                                </Card>
                                }
                                {this.props.prosthodontist.clinics &&
                                <Card name="clinics" id="clinics" styleName="practitioner__profile-card">
                                    <CardBody>
                                        <h3 styleName="practitioner__profile-title">Locations</h3>
                                        {this.renderClinics()}
                                    </CardBody>
                                </Card>
                                }
                                <Card name="contact" id="contact" styleName="practitioner__profile-card">
                                    <CardBody>
                                        <h3 styleName="practitioner__profile-title">Make an Enquiry</h3>
                                        <Form
                                            type='prosthodontist-enquiry'
                                            onSubmit={this.onEnquirySubmit}
                                            submissionData={this.props.submissionData}
                                            clinics={this.props.prosthodontist.clinics}
                                            enableReinitialize={true}
                                            initialValues={initialValues}
                                            validationSchema={validationSchema}
                                        />
                                    </CardBody>
                                </Card>
                            </div>
                        </div>
                    </div>
                </Section>
            </Fragment>
        );
    }
}

function mapStateToProps(state, ownProps) {
    // const { match: { params: { prosthodontistSlug } } } = ownProps;
    const {data: {forms, prosthodontists: {prosthodontist}}} = state;

    return {
        prosthodontist,
        submissionData: forms.data
    };
}

const mapDispatchToProps = dispatch => {
    return {
        getProsthodontist: async (slug) => {
            dispatch(getProsthodontist(slug));
        },
        submitEnquiryForm: (formName, data, setSubmitting) => dispatch(submitEnquiryForm(formName, data, setSubmitting))
    }
}

function loadData(store, pathKeys) {
    const {prosthodontistSlug} = pathKeys;

    return store.dispatch(getProsthodontist(prosthodontistSlug));
}

export default {
    component: connect(
        mapStateToProps,
        mapDispatchToProps
    )(withStyles(styles)(PractitionerSingle))
};
