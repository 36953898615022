import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import withStyles from 'isomorphic-style-loader/lib/withStyles';
import {
    Card,
    CardImg,
    CardBody,
    CardTitle,
    CardText,
    CardLink
} from 'reactstrap';


import styles from './styles.scss';


const CardEvent = (props) => {
    const style = props.theme ? `event-card-${props.theme}` : 'event-card';

    return (
        <Card styleName={style}>
            <Link to={props.link} styleName="event-card__image">{props.img}</Link>
            <CardBody styleName="event-card__body">
                <div styleName="event-card__date">
                    <div styleName="event-card__date-icon">
                        <FontAwesomeIcon icon={['far', 'calendar']}/>
                        <span>{props.day}</span>
                    </div>
                    <span>{props.month}</span>
                    <span>{props.year}</span>
                </div>
                <div styleName="event-card__content">
                    <CardTitle styleName="event-card__title">
                        <Link to={props.link}>{props.title}</Link>
                    </CardTitle>
                    <CardText>
                        {props.children}
                    </CardText>
                    <CardLink tag="div">
                        <Link to={props.link}>
                              More <FontAwesomeIcon icon={['far', 'long-arrow-right']}/>
                        </Link>
                    </CardLink>
                </div>
            </CardBody>
        </Card>
    );
};



CardEvent.propType = {
    img: PropTypes.string,
    title: PropTypes.string,
    day: PropTypes.string,
    month: PropTypes.string,
    year: PropTypes.string,
    link: PropTypes.string,
    theme: PropTypes.string,
}


CardEvent.defaultProps = {
    img: '',
    title: '',
    day: '',
    month: '',
    year: '',
    link: '',
    theme: ''
}

export default withStyles(styles)(CardEvent);