import postApi from '../posts/api'

export default class pageAPI extends postApi {
    async getUniversities(page=1, filters = {}) {
        if (page !== 1) {
            filters['page'] = page
        }

        let queryString = Object.keys(filters).map(key => key + '=' + filters[key]).join('&');

        queryString = queryString ? `?${queryString}` : '';

        const pages = await this.fetchFromAPI(
            `/universities/${queryString}`,
            'get-universities',
            'Get list of Universities',
            'get'
        );

        return pages;
    }


    async getUniversity(universityId, useSlug=false) {
        if (useSlug) return await this.getUniversities(1, {slug: universityId});

        const university = await this.fetchFromAPI(
            `/universities/${universityId}/`,
            'get-university',
            'Get Single University',
            'get'
        );

        return university;
    }

    async getFullTimeAcademics(universityId) {
        const filters = {
            type: 'university-academic',
            university: universityId,
            page_size: 15
        }

        const data = await this.getPosts(1,filters);

        return data
    }

    async getVisitingInstructors(universityId) {
        const filters = {
            type: 'university-visiting-instructor',
            university: universityId,
            page_size: 15
        }

        const data = await this.getPosts(1,filters);

        return data
    }

    async getCurrentResearch(universityId, pageSize) {
        const filters = {
            type: 'university-research',
            university: universityId,
            page_size: pageSize ? pageSize : 6
        }

        const data = await this.getPosts(1,filters);

        return data
    }

    async getUniversityEvents(universityId, page=1, filters = {}) {
        if (page !== 1) {
            filters['page'] = page
        }

        filters['university'] = universityId;

        let queryString = Object.keys(filters).map(key => key + '=' + filters[key]).join('&');

        queryString = queryString ? `?${queryString}` : '';

        const pages = await this.fetchFromAPI(
            `/events/${queryString}`,
            'get-events',
            'Get list of Events',
            'get'
        );

        return pages;
    }
}