import React, {Fragment, useEffect} from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import {Helmet} from "react-helmet";
import {Row, Col} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import GoogleMapReact from 'google-map-react';
import {Link as AnchorLink} from 'react-scroll';

import universityEnhancer, {loadSingleUniversity} from '../universityEnhancer';
import styles from './styles.scss';
import Section from '../../components/Section';
import {titleCase} from "../../utils";
import Academics from './components/Academics';
import Instructors from './components/Instructors';
import Students from './components/Students';
import Research from './components/Research';

import Button from '../../components/Button';
import {Link} from "react-router-dom";


const UniversitySingle = (props) => {
    const {
        isUpdating,
        singleUniversity,
        universities,
        getUniversity,
        getHero,
        getAcademics,
        getInstructors,
        getResearch,
        getEvents,
        getFeaturedImage,
        pageUrl} = props;

    const googleMapsAPI = 'AIzaSyD642OyDvbpyd8jZ4pjibXVmbbxUCYKO3I';

    const location = singleUniversity.location;

    useEffect(() => {
        getUniversity();
    }, []);

    useEffect(() => {
        const { id, ready } = singleUniversity;

        if (!isUpdating && ready) {
            getAcademics(id);
            getInstructors(id);
            getResearch(id);
            getEvents(id);
        }
    }, [props.singleUniversity.ready]);

    const getHelmet = () => {
        const pageTitle = singleUniversity.name;
        return (
            <Helmet>
                <title>{ titleCase(pageTitle) }</title>
            </Helmet>
        )
    }

    const MapMarker = () => {
        return (
            <div styleName="map-marker">
                <FontAwesomeIcon icon={['fas', 'map-marker-alt']}/>
            </div>
        )
    };

    const geo = {
        lat: singleUniversity.location.latitude ? parseFloat(singleUniversity.location.latitude) : null,
        lng: singleUniversity.location.longitude ? parseFloat(singleUniversity.location.longitude) : null
    }

    return (
        <Fragment>
            {getHelmet()}
            {getHero(singleUniversity, singleUniversity.name, 0.6)}
            <Section tag="section" wrap={true} isTop={true}>
                <div styleName="university-main-content">
                    <h2 styleName="university-title">Prosthodontics at {singleUniversity.name}</h2>
                    <p>{singleUniversity.about}</p>
                    <div className="text-center">
                        <a styleName="button" href="#contact">Contact & Location</a>
                    </div>
                </div>
                <div styleName="university-quick-links" className="d-none d-md-block">
                    <ul className="list-unstyled">
                        <li styleName="university-quick-links__list-item">
                            <AnchorLink to="fulltimeAcademics" smooth={true} duration={500}>
                                Full Time Academics <FontAwesomeIcon icon={['fas', 'long-arrow-right']}/>
                            </AnchorLink>
                        </li>
                        <li styleName="university-quick-links__list-item">
                            <AnchorLink to="visitingInstructors" smooth={true} duration={500}>
                                Visiting Instructors <FontAwesomeIcon icon={['fas', 'long-arrow-right']}/>
                            </AnchorLink>
                        </li>
                        <li styleName="university-quick-links__list-item">
                            <AnchorLink to="currentStudents" smooth={true} duration={500}>
                                Current Students <FontAwesomeIcon icon={['fas', 'long-arrow-right']}/>
                            </AnchorLink>
                        </li>
                        <li styleName="university-quick-links__list-item">
                            <AnchorLink to="currentResearch" smooth={true} duration={500}>
                                Current Research <FontAwesomeIcon icon={['fas', 'long-arrow-right']}/>
                            </AnchorLink>
                        </li>
                        <li styleName="university-quick-links__list-item">
                            <AnchorLink to="events" smooth={true} duration={500}>
                                Events <FontAwesomeIcon icon={['fas', 'long-arrow-right']}/>
                            </AnchorLink>
                        </li>
                    </ul>
                </div>
            </Section>
            <Academics university={singleUniversity} getFeaturedImage={getFeaturedImage} />
            <Instructors university={singleUniversity}  getFeaturedImage={getFeaturedImage} />
            <Students university={singleUniversity}  getFeaturedImage={getFeaturedImage} />
            <Research university={singleUniversity}  getFeaturedImage={getFeaturedImage} slug={singleUniversity.slug}/>
            <Section
                id="events"
                tag="section"
                wrap={true}
                title={<Fragment>Upcoming Events <FontAwesomeIcon icon={['far', 'calendar-alt']}/></Fragment>}
                titleHr={true}
            >
            </Section>
            <Section id="contact" styleName="section-alt" tag="section" wrap={true}>
                <div styleName="section-header">
                    <h2 styleName="university-title-small">Contact & Location</h2>
                </div>
                {location
                && geo.lat
                && geo.lng &&
                <div styleName="university-map">
                    <GoogleMapReact
                        bootstrapURLKeys={{key: googleMapsAPI, language: 'en'}}
                        defaultCenter={geo}
                        defaultZoom={16}
                    >
                        <MapMarker lat={geo.lat} lng={geo.lng}/>
                    </GoogleMapReact>
                </div>
                }
                <div styleName="university-contact-details">
                    {location &&
                    <div className="text-center">
                        <FontAwesomeIcon icon={['far', 'map-marker-alt']}/>
                        {` ${location.street} ${location.suburb}
                         ${location.city} ${location['state_verbose']}
                          ${location['country_verbose']} ${location.postcode}`}
                    </div>
                    }
                    <div className="text-center">
                        <FontAwesomeIcon icon={['far', 'phone']}/> {singleUniversity.phone}
                        <FontAwesomeIcon icon={['far', 'globe']}/>
                        <a href={singleUniversity.website} target="_blank" rel="noopener noreferrer">
                            {singleUniversity.website}
                        </a>
                        <FontAwesomeIcon icon={['far', 'envelope']}/>
                        <a href={`mailto:${singleUniversity.email}`} target="_top">
                            Email University
                        </a>
                    </div>
                </div>
                </Section>
        </Fragment>
    )
};

const EnhancedUniversitySingle = universityEnhancer(withStyles(styles)(UniversitySingle));

export default {
    loadData: loadSingleUniversity(),
    component: EnhancedUniversitySingle
};

