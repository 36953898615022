import {
	SUBMIT_FORM,
	FORM_SUBMITTED
} from "./actions";


const initialState = {
	isSubmitting: false,
	data: {
		formName: '',
		errors: false,
		data: false
	}
};

export const reducer = (state = initialState, action) => {
	switch (action.type) {
		case SUBMIT_FORM:
			return Object.assign({}, initialState, {
				isSubmitting: true,
				data: initialState.data
			});
		case FORM_SUBMITTED:
			return Object.assign({}, initialState, {
				isSubmitting: false,
				data: {
					formName: action.formName,
					errors: action.errors,
					data: action.data
				}
			});
		default:
			return initialState;
	}
};


