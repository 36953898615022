import React, {Component, Fragment} from 'react';
import { connect } from 'react-redux';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Slider from "react-slick";
import {Link} from 'react-router-dom';
import { gsap } from "gsap";
import InViewMonitor from 'react-inview-monitor';

import withPageSubscription, {loadData} from '../withPageSubscription';
import {withRouter} from "react-router-dom";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import DraftToHtml from '../../components/DraftToHtml';
import styles from "./styles.scss";
import Section from '../../components/Section';
import Button from '../../components/Button';
import { getStats } from "../../data/stats/actions";
import { getUniversities}  from "../../data/universities/actions";


class Community extends Component {
	constructor(props) {
		super(props);

		this.state = {
			statsInView: false,
			memberCount: {value: 0},
			studentCount: {value: 0},
			uniCount: {value: 0}
		};

		this.statsInView = this._statsInView.bind(this);
		this.statsNotInView = this._statsNotInView.bind(this);
		this.animateStats = this._animateStats.bind(this);
	}

	componentDidMount() {
		this.props.getStats();
		this.props.getPartneredUniversities();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.stats.loaded !== prevProps.stats.loaded && this.state.statsInView) {
			this.animateStats();
		}
	}

	countIt(property, max) {
		const animateObject = this.state[property];

		gsap.to(animateObject, {
			value: max,
			ease: "none",
			duration: 2,
			onUpdate: () => {
				this.setState({
					[property]: {value: Math.round(animateObject.value)}
				})
			}
		});
	}

	_statsInView() {
		this.setState({statsInView: true});
		if (this.props.stats.loaded) {
			this.animateStats();
		}
	}

	_statsNotInView() {
		// Reset stats to 0
		this.setState({
			statsInView: false,
			memberCount: {value: 0},
			studentCount: {value: 0},
			uniCount: {value: 0}
		});
	}

	_animateStats() {
		this.countIt('memberCount', this.props.stats.memberCount);
		this.countIt('studentCount', this.props.stats.studentMemberCount);
		this.countIt('uniCount', this.props.stats.partneredUniversityCount);
	}

	render() {
		const { page }  = this.props;

		const carouselSettings = {
			arrows: false,
			dots: true,
			slidesToShow: 5,
			infinite: false,
			centerPadding: '50px',
			responsive: [
				{
					breakpoint:  991,
					settings: {
						centerMode: true,
						slidesToShow: 3,
						centerPadding: '150px',
						infinite: true
					}
				},
				{
				  breakpoint:  767,
				  settings: {
					  centerPadding: '50px',
					  centerMode: true,
					  slidesToShow: 2,
					  infinite: true
				  }
				},
				{
					breakpoint: 575,
					settings: {
						slidesToShow: 2,
						centerMode: true,
						centerPadding: '30px',
						infinite: true
					}
				}
			]
		};

		const renderAffiliates = () => {
			const affiliates = this.props.getField('corporate_affiliations');

			if (Array.isArray(affiliates)){
				return affiliates.map((affiliate) => {
					return (
						<div key={affiliate.id} styleName="affiliations__item">
							<a styleName="affiliations__link" style={{ backgroundImage: `url(${affiliate.url})`}}>
							</a>
						</div>
					)
				});
			}

			return null;
		};


		const renderUniversities = (countryCode) => {
			return this.props.universities.filter((university) => {
				const { location } = university;

				if (location) {
					return university.partnered && location.country === countryCode;
				}

				return false;
			}).map((university) => {
				let featuredImageUrl = '';

				if (university.featuredImage) {
					const medLarge = university.featuredImage.sizes.find((size) => {
						return size.size === 'medium_large';
					});

					featuredImageUrl = medLarge.url;
				}

				return (
					<div key={university.id} styleName="universities__university">
						<a href={`/universities/${university.slug}`} style={{backgroundImage: `url(${featuredImageUrl})`}}>
							<h4 styleName="universities__name">{university.name}</h4>
						</a>
					</div>
				)
			});
		};

		const becomeAMember = this.props.getField('become_a_member');

		const featureImageSizes = `(min-width: 1200px) 290px,
            (min-width: 992px) 240px, 
            (min-width: 768px) 210px, 
            (min-width: 576px) 420px ,100vw`;

		return (
			<Fragment>
					{this.props.getHero(page.title, 0.6)}
					<Section wrap={true} isTop={true} paddingBottom={false}>
						<div styleName="intro">
							<h1 styleName="intro__title">{page.subtitle}</h1>
							<DraftToHtml rawContentState={page.content}/>
						</div>
					</Section>
					<Section paddingTop={false}>
						<div styleName="stats">
							<InViewMonitor
								onInView={this.statsInView}
								onNotInView={this.statsNotInView}
								repeatOnInView={true}
								styleName="stats"
							>
								<div styleName="stats__stat">
									<div styleName="stats__col">
										<div styleName="stats__icon">
											<FontAwesomeIcon icon={['fas', 'users']}/>
										</div>
										<div styleName="stats__count">
											{ this.state.memberCount.value }
										</div>
										<p>Members across Australia & New Zealand</p>
										<Button link='/membership/'>Become a Member</Button>
									</div>
								</div>
								<div styleName="stats__stat">
									<div styleName="stats__col">
										<div styleName="stats__icon">
											<FontAwesomeIcon icon={['fas', 'university']}/>
										</div>
										<div styleName="stats__count">
											{ this.state.uniCount.value }
										</div>
										<p>Partnered Universities</p>
										<Button link='/about/become-a-prosthodontist/'>Find a University</Button>
									</div>
								</div>
								<div styleName="stats__stat">
									<div styleName="stats__col">
										<div styleName="stats__icon">
											<FontAwesomeIcon icon={['fas', 'user-graduate']}/>
										</div>
										<div styleName="stats__count">
											{ this.state.studentCount.value }
										</div>
										<p>Student Prosthodontist Members</p>
										<Button link='/membership/'>Become a Student Member</Button>
									</div>
								</div>
							</InViewMonitor>
						</div>
					</Section>
					<Section>
						<div styleName="affiliations">
							<h2 styleName="affiliations__title">Corporate Affiliations</h2>
							<Slider styleName="affiliations__list" {...carouselSettings}>
								{renderAffiliates()}
							</Slider>
						</div>
					</Section>
					<Section wrap={true} styleName="join">
						<div styleName="join__image">
							{ this.props.getFeaturedImage(featureImageSizes, 'uncropped') }
						</div>
						<div styleName="join__content">
							<h2>{ becomeAMember && becomeAMember.title}</h2>
							{ becomeAMember &&
								<DraftToHtml rawContentState={becomeAMember.content}/>
							}
							<Link to="/membership/" styleName="join__button">
								Join the AANZP
								<FontAwesomeIcon icon={['far', 'long-arrow-right']}/>
							</Link>
						</div>
					</Section>
					<Section styleName="universities" wrap={true}>
						<div styleName="universities__wrap">
							<div styleName="universities__header">
								<h2>
									Become a Prothodontist at one of these Universities
								</h2>
							</div>
							<h3 styleName="universities__section-title">
								<span>Australia</span>
							</h3>
							<div styleName="universities__list">
								{ renderUniversities('AU') }
							</div>
							<h3 styleName="universities__section-title">
								<span>New Zealand</span>
							</h3>
						</div>
					</Section>
			</Fragment>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
    const { data:  { stats, universities } } = state;

    return {
    	stats,
		universities: universities.universities.data
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getStats: () => dispatch(getStats()),
		getPartneredUniversities: () => dispatch(getUniversities(1, {partnered: true}))
    }
};

const loadRequiredData = () => {
    return async (store, pathKeys, matchPath) => {
        const loadPageData = loadData();

        return await loadPageData(store, pathKeys, matchPath);
    }
};

const connectedCommunity = connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Community));

export default {
    loadData: loadRequiredData(),
	component: withRouter(withPageSubscription(connectedCommunity))
};
