import React, { Fragment } from 'react';
import withStyles from "isomorphic-style-loader/lib/withStyles";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import Section from '../../../../components/Section';
import Card from '../../../../components/CardStandard';

import styles from "./styles.scss";
import {convertFromRaw} from "draft-js";
import {Link} from "react-router-dom";


const Research = (props) => {
	const {university, getFeaturedImage, slug} = props;

	const getExcerpt = (post, length=130) => {
		if (post.content) {
			const contentState = convertFromRaw(JSON.parse(post.content));
			const plainText = contentState.getPlainText('').replace(/(\r\n|\n|\r)/gm, " ");;

			let trimmed = plainText.substring(0, length);
			trimmed = trimmed.substr(0, Math.min(trimmed.length, trimmed.lastIndexOf(" ")));

			return `${trimmed}...`;
		}
	};

	const getLink = (post) =>  {
		return `/${post.postType}/${post.slug}/`;
	}

	const renderResearch = () => {
		return university.research.slice(0, 6).map(research => {
			return (
				<div key={research.id} styleName="feed__item">
					<Card
						title={research.title}
						subtitle={research.subtitle}
						img={getFeaturedImage(research)}
						unstyled={true}
						link={getLink(research)}
					>
						{ getExcerpt(research) }
					</Card>
				</div>
			)
		})
	};

	if (university && university.research) {
		return (
			<Section
				id="currentResearch"
				tag="section"
				wrap={true}
				title={<Fragment>Current Research <FontAwesomeIcon icon={['far', 'microscope']}/></Fragment>}
				titleHr={true}
				titleCTA={<Link to={`/universities/${slug}/research`}>View All <FontAwesomeIcon icon={['far', 'long-arrow-right']}/></Link>}
			>
				<div styleName="feed">
					<div styleName="feed__row">
						{ renderResearch() }
					</div>
				</div>
			</Section>
		)
	}

	return null;
};

export default withStyles(styles)(Research)