import API from "./api";

export const CREATE_NEW_EVENT = 'CREATE_NEW_EVENT';
export const NETWORK_REQUEST_IN_PROGRESS = 'NETWORK_REQUEST_IN_PROGRESS';
export const SET_CURRENT_EVENT = 'SET_CURRENT_EVENT';
export const RESET_EVENT_DID_UPDATE = 'RESET_EVENT_DID_UPDATE';

export const REQUEST_PAGINATED_EVENTS = 'REQUEST_PAGINATED_EVENTS';
export const RECEIVE_PAGINATED_EVENTS = 'RECEIVE_PAGINATED_EVENTS';
export const RECEIVE_EVENT_SUGGESTIONS = 'RECEIVE_EVENT_SUGGESTIONS';
export const CLEAR_EVENT_SUGGESTIONS = 'CLEAR_EVENT_SUGGESTIONS';
export const NETWORK_ERROR = 'NETWORK_ERROR';


function setCurrentEvent(data) {
    const {
        id,
        status,
        slug,
        title,
        content,
        cost,
        date,
        location,
        updated_on: lastUpdated,
        created_on: created,
        university_name: universityName,
        meta,
    } = data;

    return {
        type: SET_CURRENT_EVENT,
        id,
        status,
        slug,
        title,
        content,
        featuredImage: data['featured_image'] ? data['featured_image'] : data.featuredImage,
        cost,
        date,
        location,
        created,
        lastUpdated,
        universityName,
        meta
    }
}

function resetEventDidUpdate() {
    return {
        type: RESET_EVENT_DID_UPDATE
    }
}

const createNewEvent = (isUpdating = false) => ({
    type: CREATE_NEW_EVENT,
    isUpdating
});

const clearEventSuggestions = () => ({
    type: CLEAR_EVENT_SUGGESTIONS
});

function requestInProgress() {
    return {
        type: NETWORK_REQUEST_IN_PROGRESS
    }
}

function requestingEvents() {
    return {
        type: REQUEST_PAGINATED_EVENTS
    }
}

function receivedEvents(data) {
    const {count, current_page: currentPage, next, previous, results} = data;

    const events = results.map(post => {
        const {
            id,
            status,
            slug,
            title,
            content,
            featured_image: featuredImage,
            cost,
            date,
            location,
            updated_on: lastUpdated,
            created_on: created,
            university_name: universityName,
            meta
            } = post;
        return {
            id,
            status,
            slug,
            title,
            content,
            featuredImage,
            cost,
            date,
            location,
            created,
            universityName,
            lastUpdated,
            meta
        }
    });

    return {
        type: RECEIVE_PAGINATED_EVENTS,
        count,
        currentPage,
        next,
        previous,
        data: events
    }
}

function receiveEventSuggestions(data) {
    const results = data.results;

    results.push({title: 'None', id: null});

    return {
        type: RECEIVE_EVENT_SUGGESTIONS,
        results: results
    }
}

function networkError() {
    return {
        type: NETWORK_ERROR
    }
}

function delayedResetEventDidUpdate(ms) {
    return dispatch => {
        setTimeout(() => {
            dispatch(resetEventDidUpdate());
        }, (ms));
    }
}

function getEvents(paginatedPage=1, filters={}) {
    return async(dispatch, getState, api) => {
        const {data: {events: {events: {isRequesting} } } } = getState();

        if (!isRequesting) {
            const dataAPI = new API(api);

            dispatch(requestingEvents());

            const json = await dataAPI.getEvents(paginatedPage, filters);

            return dispatch(receivedEvents(json.data));
        }

        return Promise.resolve();
    }
}

function getEvent(eventId, useSlug=false) {
    return async(dispatch, getState, api) => {
        const {data: {events: {events: {data} } } } = getState();

        // Reset current event
        await dispatch(createNewEvent(true));

        // Check loaded events before making network request
        let event = data.find(event => {
            if (useSlug) {
                return event.slug === eventId;
            } else {
                return parseInt(event.id) === parseInt(eventId);
            }
        });

        if (!event) {
            const dataAPI = new API(api);

            dispatch(requestInProgress());

            const json = await dataAPI.getEvent(eventId, useSlug);

            if (useSlug && json.data.results.length > 0) {
                event =  json.data.results[0];
            } else {
                event = json.data;
            }
        }

        dispatch(setCurrentEvent(event));

        return dispatch(delayedResetEventDidUpdate(2000));
    }
}

function getEventSuggestions(nameContains, exclude=null) {
    return async(dispatch, getState, api) => {
        const dataAPI = new API(api);

        let filter = {
            title__icontains: nameContains
        }

        if (exclude) filter['exclude'] = exclude;

        const json = await dataAPI.getEvents(1, filter);

        return dispatch(receiveEventSuggestions(json.data));
    }
}

export { getEvents, getEvent, createNewEvent, getEventSuggestions, clearEventSuggestions };