import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from 'react-router-dom';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import {Container, Row, Col} from 'reactstrap';
import moment from 'moment';

import styles from './styles.scss';
import logo from './aanzp-logo-horizontal.svg';


const Footer = () => {
    return (
        <div styleName="footer">
            <Container>
                <Row styleName="footer-nav">
                    <Col xs="12" md="3">
                        <img src={logo} alt="AANZP" />
                    </Col>
                    <Col xs="6" sm="4" md="3">
                        <ul className="list-unstyled">
                            <li><Link to="/about">About</Link></li>
                            <li><Link to="/contact">Contact</Link></li>
                            <li><Link to="/news">News</Link></li>
                        </ul>
                    </Col>
                    <Col xs="6" sm="4" md="3">
                        <ul className="list-unstyled">
                            <li><Link to="/community">Community</Link></li>
                            <li><Link to="/events">Events</Link></li>
                            <li><a href="https://members.aanzp.com.au" target="_blank" rel="noopener noreferrer">Member Login</a></li>
                        </ul>
                    </Col>
                    <Col xs="6" sm="4" md="3">
                        <ul className="list-unstyled">
                            <li><Link to="/patient-resources">Patient Resources</Link></li>
                            <li><Link to="/find-a-prosthodontist">Find a Prosthodontist</Link></li>
                            <li styleName="social-links">
                                <ul className="list-inline">
                                    <li className="list-inline-item">
                                        <a href="https://www.facebook.com/prosthodontists/" target="_blank" rel="noopener noreferrer">
                                            <FontAwesomeIcon icon={['fab', 'facebook']}/>
                                        </a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a href="https://twitter.com/TheAANZP/" target="_blank" rel="noopener noreferrer">
                                            <FontAwesomeIcon icon={['fab', 'twitter']}/>
                                        </a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </Col>
                </Row>
            </Container>
            <div styleName="footer-copyright">
                <Container>
                    <Row>
                        <Col xs="12" className="flex">
                            © {moment().format('YYYY')} All Rights Reserved - Academy of Australian and New Zealand Prosthodontists  AANZP
                             <span styleName="site-by">
                                 Site by <a href="https://www.ahamo.com.au" target="_blank" rel="noopener noreferrer">Ahamo Digital</a>
                             </span>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}

export default withStyles(styles)(Footer);