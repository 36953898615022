import React from 'react';
import {Link} from 'react-router-dom';
import withStyles from "isomorphic-style-loader/lib/withStyles";

import styles from './styles.scss';

const Buttons = (props) => {
    return (
        <Link to={props.link} styleName="button">{props.children}</Link>
    )
};

export default withStyles(styles)(Buttons);