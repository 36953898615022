import 'react';

import App from '../client/App';
import HomePage from '../client/pages/HomePage/';
import PractitionerSingle from '../client/pages/PractitionerSingle';
import About from '../client/pages/About';
import Events from '../client/pages/Events';
import News from '../client/pages/News';
import PatientResources from '../client/pages/PatientResources';
import EventSingle from '../client/events/EventSingle';
import NewsSingle from '../client/posts/NewsSingle';
import UniversitySingle from '../client/universities/UniversitySingle';
import UniversityResearchFeed from '../client/universities/UniversitySingle/components/ResearchFeed';
import FindAProsthodontist from '../client/pages/FindAProsthodontist';
import Community from '../client/pages/Community';
import BecomeAMember from '../client/pages/BecomeAMember';

export default [
	{
		...App,
		routes: [
			{	
				...HomePage,
				path: '/',
				exact: true
			},
			{
				...About,
				path: '/about',
			},
			{
				...Events,
				path: '/events',
				exact: true
			},
			{
				...News,
				path: '/news',
				exact: true
			},
			{
				...PatientResources,
				path: '/patient-resources',
			},
			{
				...PractitionerSingle,
				path: '/prosthodontist/:prosthodontistSlug',
				exact: true
			},
			{
				...EventSingle,
				path: '/events/:eventSlug',
				exact: true
			},
			{
				...NewsSingle,
				path: '/news/:postSlug',
				exact: true
			},
			{
				...NewsSingle,
				path: '/university-research/:postSlug',
				exact: true
			},
			{
				...UniversitySingle,
				path: '/universities/:universitySlug',
				exact: true
			},
			{
				...UniversityResearchFeed,
				path: '/universities/:universitySlug/research',
				exact: true
			},
			{
				...FindAProsthodontist,
				path: '/find-a-prosthodontist',
				exact: true
			},
			{
				...Community,
				path: '/community',
				exact: true
			},
			{
				...BecomeAMember,
				path: '/membership',
				exact: true
			}
		]
	}
];