import React, {Fragment} from 'react';
import { FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';

import styles from './styles.scss';

const Clinic = (props) => {
    const googleMapsAPI = 'AIzaSyD642OyDvbpyd8jZ4pjibXVmbbxUCYKO3I';
    
    const MapMarker = () => {
        return (
            <div styleName="clinic__map-marker">
                <FontAwesomeIcon icon={['fas', 'map-marker-alt']}/>
            </div>
        )
    }

    return (
        <div styleName="clinic">
            {props.centerMap.lat && props.centerMap.lng &&
            <div styleName="clinic__map">
                <GoogleMapReact
                    bootstrapURLKeys={{key: googleMapsAPI, language: 'en'}}
                    defaultCenter={props.centerMap}
                    defaultZoom={props.mapZoom}
                >
                    <MapMarker lat={props.centerMap.lat} lng={props.centerMap.lng}/>
                </GoogleMapReact>
            </div>
            }
            <h5 styleName="clinic__name">{props.clinicName}</h5>
            <dl styleName="clinic__details">
                <dt>
                    <FontAwesomeIcon icon={['far', 'map-marker-alt']}/>
                </dt>
                <dd>
                    {props.address.street}<br/>
                    {props.address.country && props.address.country.toLowerCase() === 'australia'
                    && <Fragment>{props.address.suburb}<br/></Fragment>}
                    {props.address.country && props.address.country.toLowerCase() === 'new zealand'
                    && <Fragment>{props.address.city}<br/></Fragment>}
                    {props.address.state}, {props.address.country}<br/>
                    {props.address.postcode}
                </dd>

                <dt>
                    <FontAwesomeIcon icon={['far', 'phone']}/>
                </dt>
                <dd>
                    {props.phone}
                </dd>

                <dt>
                    <FontAwesomeIcon icon={['far', 'globe']}/>
                </dt>
                <dd>
                    {props.website}
                </dd>

                <dt>
                    <FontAwesomeIcon icon={['far', 'envelope']}/>
                </dt>
                <dd>
                    <a href={`mailto:${props.email}`} target="_top">Email the clinic</a>
                </dd>
            </dl>
        </div>
    )
}

Clinic.propTypes = {
    clinicName: PropTypes.string,
    centerMap: PropTypes.object,
    mapZoom: PropTypes.number,
    address: PropTypes.object,
    phone: PropTypes.string,
    website: PropTypes.string,
    email: PropTypes.string,
    facebook: PropTypes.string,
    instagram: PropTypes.string,
    twitter: PropTypes.string
};

Clinic.defaultProps = {
    clinicName: '',
    centerMap: {lat: null, lng: null},
    mapZoom: 10,
    address: {
        street: '',
        suburb: '',
        city: '',
        state: '',
        country: '',
        postcode: ''
    },
    phone: '',
    website: '',
    email: '',
    facebook: '',
    instagram: '',
    twitter: '',
};

export default withStyles(styles)(Clinic);