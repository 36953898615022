import API from "./api";

export const CREATE_NEW_POST = 'CREATE_NEW_POST';
export const NETWORK_REQUEST_IN_PROGRESS = 'NETWORK_REQUEST_IN_PROGRESS';
export const SET_CURRENT_POST = 'SET_CURRENT_POST';
export const RESET_POST_DID_UPDATE = 'RESET_POST_DID_UPDATE'

export const REQUEST_PAGINATED_POSTS = 'REQUEST_PAGINATED_POSTS';
export const RECEIVE_PAGINATED_POSTS = 'RECEIVE_PAGINATED_POSTS';
export const RECEIVE_POST_SUGGESTIONS = 'RECEIVE_POST_SUGGESTIONS';
export const CLEAR_POST_SUGGESTIONS = 'CLEAR_EVENT_SUGGESTIONS';
export const NETWORK_ERROR = 'NETWORK_ERROR';


function setCurrentPost(data) {
    const {
        id,
        status,
        slug,
        title,
        subtitle,
        content,
        featured_image: featuredImage,
        updated_on: lastUpdated,
        created_on: created,
        type: postType,
        university_name: universityName,
        meta,
    } = data;

    return {
        type: SET_CURRENT_POST,
        id,
        status,
        slug,
        title,
        subtitle,
        content,
        featuredImage,
        created,
        lastUpdated,
        postType,
        universityName,
        meta
    }
}

function resetPostDidUpdate() {
    return {
        type: RESET_POST_DID_UPDATE
    }
}

const createNewPost = (isUpdating = false) => ({
    type: CREATE_NEW_POST,
    isUpdating
});

const clearPostSuggestions = () => ({
    type: CLEAR_POST_SUGGESTIONS
})

function requestInProgress() {
    return {
        type: NETWORK_REQUEST_IN_PROGRESS
    }
}

function requestingPosts() {
    return {
        type: REQUEST_PAGINATED_POSTS
    }
}

function receivedPosts(data, postType) {
    const {count, current_page: currentPage, next, previous, results} = data;

    const posts = results.map(post => {
        const {
            id,
            status,
            slug,
            title,
            subtitle,
            content,
            featured_image: featuredImage,
            updated_on: lastUpdated,
            created_on: created,
            type: postType,
            university_name: universityName,
            meta
            } = post;
        return {
            id,
            status,
            slug,
            title,
            subtitle,
            content,
            featuredImage,
            created,
            lastUpdated,
            postType,
            universityName,
            meta
        }
    });

    return {
        type: RECEIVE_PAGINATED_POSTS,
        postType,
        count,
        currentPage,
        next,
        previous,
        data: posts
    }
}

function receivePostSuggestions(data) {
    const results = data.results;

    results.push({title: 'None', id: null});

    return {
        type: RECEIVE_POST_SUGGESTIONS,
        results: results
    }
}

function networkError() {
    return {
        type: NETWORK_ERROR
    }
}

function delayedResetPostDidUpdate(ms) {
    return dispatch => {
        setTimeout(() => {
            dispatch(resetPostDidUpdate());
        }, (ms));
    }
}

function getPosts(postType, paginatedPage = 1, filters = {}) {
    return async (dispatch, getState, api) => {
        const {data: {posts: {posts: {isRequesting}}}} = getState();

        if (!isRequesting) {
            const dataAPI = new API(api);

            dispatch(requestingPosts());

            filters['type'] = postType;

            const json = await dataAPI.getPosts(paginatedPage, filters);


            return dispatch(receivedPosts(json.data, postType));
        }

        return Promise.resolve();
    }
}

function getPost(postId, type, useSlug = false) {
    return async (dispatch, getState, api) => {
        const {data: {posts: {posts: {types}}}} = getState();

        // Reset current post
        await dispatch(createNewPost(true));

        let post = false;

        // Check loaded posts before making network request
        // if (type in types) {
        //     post = types[type].data.find(post => {
        //         if (useSlug) {
        //             return post.slug === postId;
        //         } else {
        //             return parseInt(post.id) === parseInt(postId);
        //         }
        //     });
        // }
        //
        //
        // if (!post) {
        //     const dataAPI = new API(api);
        //
        //     dispatch(requestInProgress());
        //
        //     const json = await dataAPI.getPost(postId, useSlug);
        //
        //     if (useSlug && json.data.results.length > 0) {
        //         post =  json.data.results[0];
        //     } else {
        //         post = json.data;
        //     }
        //
        // }
        const dataAPI = new API(api);

        dispatch(requestInProgress());

        const json = await dataAPI.getPost(postId, useSlug);

        if (useSlug && json.data.results.length > 0) {
            post =  json.data.results[0];
        } else {
            post = json.data;
        }

        dispatch(setCurrentPost(post));

        return dispatch(delayedResetPostDidUpdate(2000));
    }
}

function getPostSuggestions(nameContains, exclude = null) {
    return async (dispatch, getState, api) => {
        const dataAPI = new API(api);

        let filter = {
            title__icontains: nameContains
        }

        if (exclude) filter['exclude'] = exclude;

        const json = await dataAPI.getPosts(1, filter);

        return dispatch(receivePostSuggestions(json.data));
    }
}

export {getPosts, getPost, createNewPost, getPostSuggestions, clearPostSuggestions};