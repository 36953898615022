export const capitalize = (string) => {
	if (string) {
		return string.replace(/^\w/, c => c.toUpperCase());
	}

	return '';
};

export const titleCase = (string) => {
	if (string) {
		return string.toLowerCase().split(' ').map(function (word) {
			return (word.charAt(0).toUpperCase() + word.slice(1));
		}).join(' ');
	}

	return '';
}
