import React from 'react';
import withStyles from "isomorphic-style-loader/lib/withStyles";

import DraftToHtml from '../../../../components/DraftToHtml';
import styles from './styles.scss';
import {Col} from "reactstrap";


const StandardContent = (props) => {
    return (
        <Col xs="12" md="9" lg="8">
            {props.page.prelude &&
                <p className="lead">
                    {props.page.prelude}
                </p>
            }
            {props.preludeAfter &&
            <div styleName="prelude-after">
                {props.preludeAfter}
            </div>}
            <DraftToHtml rawContentState={props.page.content}/>
        </Col>
    );
}

export default withStyles(styles)(StandardContent);


