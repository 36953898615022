import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import CSSModules from 'react-css-modules';
import {FullsizePicture} from 'react-responsive-picture';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import {
    Carousel,
    CarouselItem
} from 'reactstrap';

import styles from './styles.scss';


class Hero extends Component {
    constructor(props) {
        super(props);

        this.state = {activeIndex: 1};
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.goToIndex = this.goToIndex.bind(this);
        this.onExiting = this.onExiting.bind(this);
        this.onExited = this.onExited.bind(this);
    }

    onExiting() {
        this.animating = true;
    }

    onExited() {
        this.animating = false;
    }

    next() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === this.props.items.length - 1 ? 0 : this.state.activeIndex + 1;
        this.setState({activeIndex: nextIndex});
    }

    previous() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === 0 ? this.props.items.length - 1 : this.state.activeIndex - 1;
        this.setState({activeIndex: nextIndex});
    }

    goToIndex(newIndex) {
        if (this.animating) return;
        this.setState({activeIndex: newIndex});
    }

    renderCarouselItems() {
        if (this.props.items && Array.isArray(this.props.items)) {
            return this.props.items.map((item, index) => {
                const sources = item.image.map(image => {
                    return {
                        srcSet: image.url,
                        media: `(max-width: ${image.width}px)`
                    }
                });

                const largeImage = item.image.find(image => {
                    return image.size === 'hero_large'
                });

                //console.log(largeImage)

                return (
                    <CarouselItem
                        onExiting={this.onExiting}
                        onExited={this.onExited}
                        key={`carousel-item-${index}`}
                    >
                        <Link
                            styleName="carousel__item-link"
                            to={item.link ? item.link : '/'}
                            style={{backgroundImage: `url(${largeImage.url})`}}
                        >
                            {/*<FullsizePicture*/}
                                {/*wrapperClassName="carousel-picture"*/}
                                {/*styleName="carousel__image"*/}
                                {/*sources={sources}*/}
                                {/*alt={item.title}*/}
                            {/*/>*/}
                        </Link>
                    </CarouselItem>
                );
            });

        } else {
            return [];
        }
    }

    renderCarouselNavItems() {
        if (this.props.items && Array.isArray(this.props.items)) {
            return this.props.items.map((item, index) => {
                const active = index === this.state.activeIndex ? 'active' : '';

                return (
                    <li
                        key={`slide-${index}`}
                        styleName="carousel__nav-item"
                        className={active}
                        onClick={() => this.goToIndex(index)}
                    >
                        <div>
                            <h5><Link to={item.link ? item.link : '/'}>{item.title}</Link></h5>
                            <span className="d-none d-sm-block">{item.desc}</span>
                        </div>
                        <div styleName="carousel__nav-item-active"></div>
                    </li>
                )
            });
        } else {
            return [];
        }
    }

    render() {
        const {activeIndex} = this.state;

        return (
            <div styleName="carousel">
                <div styleName="carousel__nav">
                    <ul styleName="carousel__nav-list" className="list-unstyled">
                        {this.renderCarouselNavItems()}
                    </ul>
                </div>
                <div styleName="carousel__slides">
                    <Carousel
                        next={this.next}
                        previous={this.previous}
                        activeIndex={activeIndex}
                        interval={8000}
                        ride='carousel'
                    >
                        {this.renderCarouselItems()}
                    </Carousel>
                </div>
            </div>
        )
    }
}

// export default withStyles(styles)(CSSModules(Hero, styles));
export default withStyles(styles)(Hero);