import React, {Component, Fragment} from 'react';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";

import { getPageBySlug} from '../data/pages/actions';
import Hero from '../components/Hero';
import {Picture} from "react-responsive-picture";

const getChildPage = (parent, childSlug) => {
    const child = parent.children.find(child => {
        return child.slug === childSlug
    });

    return child ? child : parent;
}

const withPageSubscription = (WrappedComponent, slug='') => {
    class Page extends Component {
        constructor(props) {
            super(props);

            this.getHero = this._getHero.bind(this);
            this.getCurrentChild = this._getCurrentChild.bind(this);
            this.isPage = this._isPage.bind(this)
            this.getField = this._getField.bind(this);
            this.getImage = this._getImage.bind(this);
            this.getFeaturedImage = this._getFeaturedImage.bind(this);
            this.setHeroImage = this._setHeroImage.bind(this);

            this.state = {
                heroImage: {}
            }
        }

        componentDidMount() {
            const {pathname, search} = this.props.location;
            const params = new URLSearchParams(search);
            const forceUpdate = params.get('forceupdate');
            const pageSlug = slug ? slug : pathname.replace(/^\/|\/$/g, '').split('/')[0];

            this.pageSlug = pageSlug;

            this.props.getPageBySlug(pageSlug, forceUpdate);
            this.setHeroImage();
        }

        componentDidUpdate(prevProps, prevState, snapshot) {

            if (this.props.page && this.props.page.id !== prevProps.page.id) {
                this.setHeroImage();
            }
        }

        getHelmet() {
            const pageTitle = this.props.page.title;
            return (
                <Helmet>
                    <title>{pageTitle}</title>
                </Helmet>
            )
        }

        _setHeroImage(heroImage=false) {
            let hero = heroImage;

            if (!hero) {
                const currentPage = this.getCurrentChild() ? this.getCurrentChild() : this.props.page;

                if (currentPage) {
                    hero = currentPage.parent ? currentPage['hero_image'] : currentPage.heroImage;
                }
            }

            this.setState({
                heroImage: hero
            });
        }

        _isPage(slug) {
            if (this.props) {
                const {pathname} = this.props.location;
                const paths = pathname.replace(/^\/|\/$/g, '').split('/');
                const currentSlug = paths[paths.length - 1];

                return currentSlug === slug;
            }

            return false;
        }

        _getCurrentChild() {
            if (this.props) {
                const {pathname} = this.props.location;
                const paths = pathname.replace(/^\/|\/$/g, '').split('/');
                const currentSlug = paths[paths.length - 1];

                const childPage =  this.props.page.children.find((child) => {
                    return child.slug === currentSlug;
                });

                return childPage ? childPage : this.props.page;
            }

            return false;
        }

        _getHero(title = false, backgroundOpacity = 1 , curveFill = '#ffffff') {
            if (this.state.heroImage) {

                const sizes = this.state.heroImage ? this.state.heroImage.sizes : [];
                const alt = this.state.heroImage ? this.state.heroImage.alt : '';

                return (
                    <Hero
                        backgroundImageSizes={sizes}
                        backgroundOpacity={backgroundOpacity}
                        curveFill={curveFill}
                        alt={alt}
                    >
                        {title && <h1>{title}</h1>}
                    </Hero>
                );
            }

            return false;
        }

        _getFeaturedImage(sizes, imageSize='card') {
            const currentPage = this.getCurrentChild() ? this.getCurrentChild() : this.props.page;
            const featuredImage = currentPage ? currentPage.featuredImage : false;

            if (featuredImage) {
                const sources = featuredImage.sizes.filter(image => {
                    if (imageSize === 'uncropped') {
                        return image.size === 'medium' || image.size === 'medium_large' || image.size === 'large'
                    }

                    return image.size.startsWith(imageSize);
                }).map(image => (`${image.url} ${image.width}w`));

                const imgSrc = sources.join(', ');

                return <Picture src={imgSrc} alt={featuredImage.alt} sizes={sizes}/>
            }
        }

        _getField(fieldName) {
            const currentPage = this.getCurrentChild() ? this.getCurrentChild() : this.props.page;

            if (currentPage && currentPage.meta.length > 0) {
                const metaItem = currentPage.meta.find(item => {
                    return item['meta_key'] === fieldName;
                });

                return metaItem ? metaItem['meta_value'] : false;
            }

            return false;
        }

        _getImage(imageObject, size, returnObject=true) {
            const image = imageObject.find(image => {
                return image.size === size;
            });

            return returnObject ? image : image.url;
        }

        render() {
            return (
                <Fragment>
                    {this.getHelmet()}
                    <WrappedComponent
                        {...this.props}
                        isPage={this.isPage}
                        getChild={this.getCurrentChild}
                        getHero={this.getHero}
                        setHeroImage={this.setHeroImage}
                        getField={this.getField}
                        getImage={this.getImage}
                        getFeaturedImage={this.getFeaturedImage}
                    />
                </Fragment>
            );
        }
    }

    return connect(mapStateToProps, mapDispatchToProps)(Page);
};

const mapStateToProps = (state, ownProps) => {
    const {data: {pages: {currentPage: page} } } = state;

    const {pathname} = ownProps.location;
    const paths = pathname.replace(/^\/|\/$/g, '').split('/');
    const childSlug = paths.length > 1 ? paths[paths.length - 1] : '';

    return {
        page: childSlug ? getChildPage(page, childSlug) : page
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getPageBySlug: (slug, forceUpdate) => dispatch(getPageBySlug(slug, forceUpdate)),
    }
}

export const loadData = (slug='') => {
    return (store, pathKeys, matchPath) => {
        const pageSlug = slug ? slug : matchPath.replace(/^\/|\/$/g, '').split('/')[0];
        return store.dispatch(getPageBySlug(pageSlug))
    }
}

export default withPageSubscription;
