import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Autosuggest from 'react-autosuggest';
import withStyles from "isomorphic-style-loader/lib/withStyles";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import styles from './styles.scss';

const getSuggestionValue = suggestion => suggestion.title;

const renderSuggestion = suggestion => (
  <span>
      <Link to={`/prosthodontist/${suggestion.slug}`}>{suggestion.name}</Link>
  </span>
);

const renderSectionTitle = (section) =>{
  return (
    <strong>{section.title}</strong>
  );
};

const getSectionSuggestions = (section) => {
  return section.items;
}

class AutoSuggest extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: '',
        };

        this.onSelected = this._onSelected.bind(this);
        this.onChange = this._onChange.bind(this);
        this.onSuggestionsFetchRequested = this._onSuggestionsFetchRequested.bind(this);
        this.onSuggestionsClearRequested = this._onSuggestionsClearRequested.bind(this);
    }

    _onChange(event, {newValue}) {
        this.setState({
            value: newValue
        });
    };


    _onSelected(event, { suggestion }) {
        this.props.onSelect('parent', suggestion.id);
    }

    // Autosuggest will call this function every time you need to update suggestions.
    _onSuggestionsFetchRequested({value}){
        this.props.onSuggestionsFetchRequested(value);
    }

    // Autosuggest will call this function every time you need to clear suggestions.
    _onSuggestionsClearRequested(){
        this.props.onSuggestionsClearRequested();
    }

    render() {
        const {value} = this.state;

        // Autosuggest will pass through all these props to the input.
        const inputProps = {
            placeholder: 'Search by Name or Clinic',
            value: value,
            onChange: this.onChange
        };


        return (
            <div styleName="search-container" className="input-group mb-3">
                <div className="input-group-prepend">
                    <span className="input-group-text">
                        <FontAwesomeIcon icon={['fas', 'search']}/>
                    </span>
                </div>
                <Autosuggest
                    className="form-control"
                    theme={styles}
                    //multiSection={true}
                    suggestions={this.props.suggestions}
                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                    onSuggestionSelected={this.onSelected}
                    getSuggestionValue={getSuggestionValue}
                    renderSuggestion={renderSuggestion}
                    //renderSectionTitle={renderSectionTitle}
                    //getSectionSuggestions={getSectionSuggestions}
                    inputProps={inputProps}
                />
            </div>
        );
    }
}

export default withStyles(styles)(AutoSuggest);