import React, {Component, Fragment} from 'react';
import withStyles from "isomorphic-style-loader/lib/withStyles";
import {connect} from "react-redux";
import { Col } from 'reactstrap';
import { Switch, Route, withRouter } from 'react-router-dom';

import withPageSubscription, {loadData} from '../withPageSubscription';
import Section from '../../components/Section';
import SubNavigation from '../../components/SubNavigation';
import RelatedLinks from '../../components/RelatedLinks';
import StandardContent from './components/StandardContent';
import Board from './components/Board';
import Contact from './components/Contact';
import Universities from './components/Universities';


import styles from "./styles.scss";
import {loadPosts} from "../../posts/postEnhancer";
import { getUniversities}  from "../../data/universities/actions";
import { submitEnquiryForm } from "../../data/forms/actions";


const links = [
    {id: 1, title: 'About AANZP', link: '/about'},
    {id: 2, title: 'AANZP Council', link: '/about/aanzp-council'},
    {id: 3, title: 'Become a Prosthodontist', link: '/about/become-a-prosthodontist'},
    {id: 4, title: 'Contact', link: '/about/contact'},
];


class About extends Component {
    componentDidMount() {
		this.props.getPartneredUniversities();
	}

    render() {
        const child = this.props.getChild();
        const page = child ? child : this.props.page;

        return (
            <Fragment>
                {this.props.getHero(page.title, 0.6)}
                <Section>
                    <Col xs="12" md="3">
                        <SubNavigation navigationItems={links} position='left'/>
                    </Col>
                    <Switch>
                        <Route
                            path="/about/aanzp-council"
                            exact
                            component={(props) => <Board {...this.props}/>}
                        />
                        <Route
                            path="/about/become-a-prosthodontist"
                            exact
                            component={(props) => <StandardContent {...this.props} page={this.props.page}/>}
                        />
                        <Route
                            path="/about/contact"
                            exact
                            component={(props) => <Contact {...this.props}/>}
                        />
                        <Route
                            path="/about"
                            exact
                            component={(props) => <StandardContent {...this.props} page={this.props.page}/>}
                        />
                    </Switch>
                </Section>
                <Route
                    path="/about/become-a-prosthodontist"
                    exact
                    component={(props) => <Universities {...this.props} page={this.props.page}/>}
                />
                <RelatedLinks marginTop={!this.props.isPage('become-a-prosthodontist')}/>
            </Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    const { data:  { universities, forms } } = state;

    return {
		universities: universities.universities.data,
        submissionData: forms.data
    }
};

const mapDispatchToProps = dispatch => {
    return {
		getPartneredUniversities: () => dispatch(getUniversities(1, {partnered: true})),
        submitContactForm: (formName, data, setSubmitting) => dispatch(submitEnquiryForm(formName, data, setSubmitting))
    }
};

const loadRequiredData = () => {
    return async (store, pathKeys, matchPath) => {
        const loadPageData = loadData();
        const loadPostData = loadPosts('board-members', 1, {page_size: 100});

        await loadPageData(store, pathKeys, matchPath);
        return await loadPostData(store);
    }
};

const connectedAbout = connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(About));

export default {
    loadData: loadRequiredData(),
	component: withRouter(withPageSubscription(connectedAbout))
}