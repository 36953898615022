import React, {useState} from 'react';
import {withFormik} from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import ContactForm, {initialValues as contactInitialValues, displayName as contactDisplayName} from './ContactForm';
import MemberRegistration, {
	initialValues as memberInitialValues,
	displayName as memberDisplayName
} from './MemberRegistration';
import ProsthodontistEnquiry, {
	initialValues as prosthodontistInitialValues,
	displayName as prosthodontistDisplayName
} from "./ProsthodontistEnquiry";

const Form = (props) => {
	const getForm = (type) => {
		switch (type) {
			case 'member-registration':
				return {
				    component: MemberRegistration,
                    initialValues: memberInitialValues,
                    displayName: memberDisplayName
                }
			case 'prosthodontist-enquiry':
				return {
					component: ProsthodontistEnquiry,
					initialValues: prosthodontistInitialValues,
					displayName: prosthodontistDisplayName
				}
			default:
				return {
					component: ContactForm,
					initialValues: contactInitialValues,
					displayName: contactDisplayName
				}
		}
	}

	const innerForm = getForm(props.type);

	const formikEnhancer = withFormik({
		mapPropsToValues: (props) => props.initialValues ? props.initialValues : innerForm.initialValues,
		handleSubmit: (values, {props, setSubmitting}) => props.onSubmit(values, setSubmitting),
		displayName: innerForm.displayName,
		validationSchema: (props) => {
			return Yup.object().shape(props.validationSchema);
		},
		enableReinitialize: props.enableReinitialize
	});

	const EnhancedForm = formikEnhancer(innerForm.component);

	return (
		<EnhancedForm {...props} />
	)
};

Form.propTypes = {
	type: PropTypes.string,
	onSubmit: PropTypes.func,
	validationSchema: PropTypes.object,
	initialValues: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.bool
	]),
	enableReinitialize: PropTypes.bool

};

Form.defaultProps = {
	initialValues: false,
	validationSchema: {},
	enableReinitialize: false
};

export default Form;

