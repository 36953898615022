import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import styles from './styles.scss';
import logo from './aanzp-logo.svg'

import {
	Collapse,
	Navbar,
	NavbarToggler,
	NavbarBrand,
	Nav,
	NavItem,
	Container,
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem
} from 'reactstrap';

class NavbarContainer extends Component {
	constructor(props) {
		super(props);

		this.toggle = this.toggle.bind(this);
		this.state = {
			isOpen: false
		};
	}

	toggle() {
		this.setState({
			isOpen: !this.state.isOpen
		});
	}

	render() {
		return (
			<Navbar styleName="navbar" light expand="lg">
				<Container fluid>
					<NavbarBrand styleName="brand" tag="span">
						<Link onClick={this.toggle} to='/' >
							<img src={logo} alt="Academy of Australian & New Zealand Prosthodontists" />
						</Link>
					</NavbarBrand>
					<NavbarToggler styleName="navbar-toggler" onClick={this.toggle}>
						<FontAwesomeIcon icon={['far', 'bars']} />
					</NavbarToggler>
					<Collapse isOpen={this.state.isOpen} navbar>
						<Nav navbar className="ml-auto">
							<NavItem styleName="nav-item">
								<NavLink onClick={this.toggle} styleName="nav-link" to="/">Home</NavLink>
							</NavItem>
							<UncontrolledDropdown nav inNavbar styleName="nav-item">
								<DropdownToggle nav styleName="nav-link">
									About <FontAwesomeIcon icon={['fas', 'caret-down']}/>
								</DropdownToggle>
								 <DropdownMenu left="true" styleName="dropdown">
									 <DropdownItem>
										 <NavLink onClick={this.toggle} to="/about">
											 About AANZP
										 </NavLink>
									 </DropdownItem>
									 <DropdownItem>
										 <NavLink onClick={this.toggle} to="/about/aanzp-council">
											 AANZP Council
										 </NavLink>
									 </DropdownItem>
									 <DropdownItem>
										 <NavLink onClick={this.toggle} to="/about/become-a-prosthodontist">
											 Become a Prosthodontist
										 </NavLink>
									 </DropdownItem>
									 <DropdownItem>
										 <NavLink onClick={this.toggle} to="/about/contact">
											 Contact
										 </NavLink>
									 </DropdownItem>
								 </DropdownMenu>
							</UncontrolledDropdown>
							<UncontrolledDropdown nav inNavbar styleName="nav-item">
								<DropdownToggle nav styleName="nav-link">
									Patient Resources <FontAwesomeIcon icon={['fas', 'caret-down']}/>
								</DropdownToggle>
								 <DropdownMenu left="true" styleName="dropdown">
									 <DropdownItem>
										 <NavLink onClick={this.toggle} to="/patient-resources/dental-specialists">
											 Dental Specialists
										 </NavLink>
									 </DropdownItem>
									 <DropdownItem>
										 <NavLink onClick={this.toggle} to="/patient-resources/common-dental-procedures">
											 Common Dental Procedures
										 </NavLink>
									 </DropdownItem>
									 <DropdownItem>
										 <NavLink onClick={this.toggle} to="/patient-resources/your-first-appointment">
											 Your first appointment
										 </NavLink>
									 </DropdownItem>
									 <DropdownItem>
										 <NavLink onClick={this.toggle} to="/patient-resources/faqs">
											 Frequently Asked Questions
										 </NavLink>
									 </DropdownItem>
									 <DropdownItem>
										 <NavLink onClick={this.toggle} to="/patient-resources/external-resources">
											 External Resources
										 </NavLink>
									 </DropdownItem>
								 </DropdownMenu>
							</UncontrolledDropdown>
							<UncontrolledDropdown nav inNavbar styleName="nav-item">
								<DropdownToggle nav styleName="nav-link">
									Community <FontAwesomeIcon icon={['fas', 'caret-down']}/>
								</DropdownToggle>
								 <DropdownMenu left="true" styleName="dropdown">
									 <DropdownItem>
										 <NavLink onClick={this.toggle} to="/community">
											 AANZP Community
										 </NavLink>
									 </DropdownItem>
									 <DropdownItem>
										 <NavLink onClick={this.toggle} to="/membership">
											 Become a Member
										 </NavLink>
									 </DropdownItem>
								 </DropdownMenu>
							</UncontrolledDropdown>
							<NavItem styleName="nav-item">
								<NavLink onClick={this.toggle} styleName="nav-link" to="/news">News</NavLink>
							</NavItem>
							<NavItem styleName="nav-item">
								<NavLink onClick={this.toggle} styleName="nav-link" to="/events">Events</NavLink>
							</NavItem>
							<NavItem styleName="nav-button">
								<NavLink onClick={this.toggle} styleName="nav-link-button" to="/find-a-prosthodontist/">
									Find a Prosthodontist
								</NavLink>
							</NavItem>
						</Nav>
					</Collapse>
				</Container>
			</Navbar>
		);
	}
}

export default withStyles(styles)(NavbarContainer);
