import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import {
	Container,
	Row
} from 'reactstrap';

import styles from './styles.scss';

class Section  extends Component {

	wrapper(children) {
		let markup = children;

		if (this.props.wrap) {
			markup = (
				<div styleName="section__wrapper">
					<Row styleName="section__content" className={this.props.innerClassName}>
						{children}
					</Row>
				</div>
			);
		}

		return markup;
	}

	getTitle() {
		const title = (
			<div styleName={`section__title ${this.props.titleHr ? 'title-hr' : ''}`}>
				<h1>{this.props.title}</h1>
				{this.props.titleCTA && <span styleName="title-cta">{this.props.titleCTA}</span>}
			</div>
		);

		return this.props.title ? title : '';
	}

	renderContent() {
		if (this.props.wrapTitle) {
			return (
				<Row>
					<div styleName="section__wrapper">
						<Row>
							{ this.getTitle() }
							{ this.wrapper(this.props.children) }
						</Row>
					</div>
				</Row>
			);
		}

		return (
			<Row
				styleName={!this.props.wrap ? 'section__content' : '' }
				className={!this.props.wrap ? this.props.innerClassName : '' }
			>
				{ this.getTitle() }
				{ this.wrapper(this.props.children) }
			</Row>
		);
	}

	render() {
		let styleName = this.props.isTop ? 'section section--top' : 'section';
		styleName += this.props.isBottom ? ` section--bottom` : '';
		styleName += this.props.paddingTop ? '' : ` section--no-pt`;
		styleName += this.props.paddingBottom ? '' : ` section--no-pb`;

		const DynamicTag = this.props.tag;

		return (
			<DynamicTag id={this.props.id} className={ this.props.className } styleName={styleName}>
				<Container>
					{this.renderContent()}
				</Container>
            </DynamicTag>
		);
	}
}

Section.propTypes = {
	id: PropTypes.string,
	children: PropTypes.node,
	wrap: PropTypes.bool,
	wrapTitle: PropTypes.bool,
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.array]),
	titleHr: PropTypes.bool,
	titleCTA: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.array]),
	isTop: PropTypes.bool,
	isBottom: PropTypes.bool,
	paddingTop: PropTypes.bool,
	paddingBottom: PropTypes.bool,
    tag: PropTypes.string,
	innerClassName: PropTypes.string
};

Section.defaultProps = {
	id: '',
	title: '',
	children: '',
	wrap: false,
	wrapTitle: false,
	isTop: false,
	isBottom: false,
	paddingTop: true,
	paddingBottom: true,
    tag: 'div',
	innerClassName: '',
	titleHr: false,
	titleCTA: ''
};


export default withStyles(styles)(Section);