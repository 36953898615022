import React, {Component, Fragment} from 'react';
import withStyles from "isomorphic-style-loader/lib/withStyles";
import {Link, withRouter} from "react-router-dom";
import withPageSubscription, {loadData} from "../withPageSubscription";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {connect} from 'react-redux';

import styles from './styles.scss';
import Section from '../../components/Section';
import Hero from '../../components/Hero';
import Button from '../../components/Button';
import {loadPosts} from '../../posts/postEnhancer';
import {getProsthodontistSuggestions, clearProsthodontistSuggestions} from '../../data/prosthodontists/actions';
import LatestNews from './components/LatestNews';
import AutoSuggest from './components/AutoSuggest';

const languages = [
    {
        name: 'C',
        year: 1972
    },
    {
        name: 'Elm',
        year: 2012
    },
];

class FindAProsthodontist extends Component {
    constructor(props) {
        super(props);

        this.getSuggestions = this._getSuggestions.bind(this);
        this.clearSuggestions = this._clearSuggestions.bind(this);

        this.state = {
            suggestions: []
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.suggestedProsthodontists && this.props.suggestedProsthodontists.length !== prevProps.suggestedProsthodontists.length) {

            const prosSuggestions = this.props.suggestedProsthodontists.map((item) => {
                    return {
                        name: `${item['first_name']} ${item['last_name']}`,
                        slug: item.slug
                    }
            });

            // this.setState({
            //     suggestions: [{title: 'prosthodontists', items: prosSuggestions}]
            // });

            this.setState({
                suggestions: prosSuggestions
            });
        }
    }

    _getSuggestions(search) {
        this.props.getProsthodontistSuggestions(search);
    }

    _clearSuggestions() {
        this.props.clearProsthodontistSuggestions();
    }

    render() {
        const page = this.props.page;
        const heroImage = page.heroImage;
        const sizes = heroImage ? heroImage.sizes : [];
        const alt = heroImage ? heroImage.alt : '';

        return (
            <Fragment>
                <Hero
                    backgroundImageSizes={sizes}
                    backgroundOpacity={0.5}
                    curveFill='#ffffff'
                    alt={alt}
                    className='no-overflow'
                >
                    <div styleName="hero-content">
                        <h1>{page.title}</h1>
                        <div styleName="search">
                            <AutoSuggest
                                suggestions={this.state.suggestions}
                                onSuggestionsFetchRequested={this.getSuggestions}
                                onSuggestionsClearRequested={this.clearSuggestions}
                            />
                        </div>
                        <p>
                            Search our network of Specialist Prosthodontists across Australia
                            and New Zealand
                        </p>
                    </div>
                </Hero>
                <Section tag="section" wrap={true} isTop={true}>
                    <div styleName="how-to">
                        <div styleName="how-to__step">
                            <FontAwesomeIcon icon={['fas', 'search']}/>
                            <p>
                                Search our network of Specialist Prosthodontists by their name or clinic
                            </p>
                        </div>
                        <div styleName="how-to__step">
                            <FontAwesomeIcon icon={['fas', 'tooth']}/>
                            <p>
                                Locate a specialist close to you
                            </p>
                        </div>
                        <div styleName="how-to__step">
                            <FontAwesomeIcon icon={['fas', 'calendar-day']}/>
                            <p>
                                Connect with the Prosthodontists clinic
                            </p>
                        </div>
                    </div>
                    <div styleName="become-a-member">
                        <h2>Are you a Prosthodontist?</h2>
                        <p>
                            If you’re a Specialist Prosthodontist in Australia or New Zealand,
                            why not join our community and maximise your online presence.
                        </p>
                        <Button link="/">Learn More</Button>
                    </div>
                </Section>
                <Section
                    tag="section"
                    styleName="latest-news"
                    wrap={false}
                    isBottom={true}
                    title={<Fragment>Latest News <FontAwesomeIcon icon={['far', 'newspaper']}/></Fragment>}
                    titleHr={true}
                    titleCTA={<Link to="/news">View All <FontAwesomeIcon icon={['far', 'long-arrow-right']}/></Link>}
                >
                    <LatestNews/>
                </Section>
            </Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    const {data: {prosthodontists: {autoSuggest: suggestedPros}}} = state;

    return {
        suggestedProsthodontists: suggestedPros
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getProsthodontistSuggestions: (nameContains) => dispatch(getProsthodontistSuggestions(nameContains)),
        clearProsthodontistSuggestions: () => dispatch(clearProsthodontistSuggestions())
    }
}

const loadRequiredData = () => {
    return async (store, pathKeys, matchPath) => {
        const loadPageData = loadData();
        const loadPostData = loadPosts('news', 1, {page_size: 3});

        await loadPageData(store, pathKeys, matchPath);
        return await loadPostData(store);
    }
}

const ConnectedFindAProsthodontist = connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(FindAProsthodontist));

export default {
    loadData: loadRequiredData(),
    component: withRouter(withPageSubscription(ConnectedFindAProsthodontist))
}
