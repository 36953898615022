import baseApi from '../api';

export default class statsAPI extends baseApi {
	async getStats() {

        const stats = await this.fetchFromAPI(
            `stats/`,
            'get-stats',
            'Get Stats',
            'get'
        );

        return stats;
    }
}