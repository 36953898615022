import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import PropTypes from 'prop-types';
import { FullsizePicture } from 'react-responsive-picture';

import styles from './styles.scss';
import CurveMask from '../Icon/components/HeroCurve';

const Hero = (props) => {
    const stylename = props.small ? 'hero hero-small' : 'hero';
    const imageLarge = props.backgroundImageSizes.find((item => {
        return item.size === 'hero_large';
    }));

    const imageMedium = props.backgroundImageSizes.find((item => {
        return item.size === 'hero_medium';
    }));

    const imageSmall = props.backgroundImageSizes.find((item => {
        return item.size === 'hero_small';
    }));

    const sources = [];

    imageSmall ? sources.push({srcSet: imageSmall.url, media: `(max-width: ${imageSmall.width}px)`}) : sources;

    imageMedium ? sources.push({srcSet: imageMedium.url, media: `(max-width: ${imageMedium.width}px)`}) : sources;

    imageLarge ? sources.push({srcSet: imageLarge.url, media: `(max-width: ${imageLarge.width}px)`}) : sources;

    return (
        <div styleName={stylename} className={props.className}>
            {!props.simple &&
                <div styleName="hero__background">
                    <FullsizePicture
                        style={{opacity: `${props.backgroundOpacity}`}}
                        sources={sources}
                        alt={props.alt}
                    />
                </div>
            }
            {props.simple &&
                <div styleName="hero__background" style={{backgroundImage: `url(${props.backgroundImage})`}} />
            }
            {props.children}
            <CurveMask className={styles['hero__curve-mask']} inlineStyle={{fill: props.curveFill}}/>
        </div>
    );
}

Hero.propTypes = {
    backgroundImageSizes: PropTypes.array,
    backgroundImage: PropTypes.string,
    backgroundOpacity: PropTypes.number,
    small: PropTypes.bool,
    curveFill: PropTypes.string,
    alt: PropTypes.string,
    simple: PropTypes.bool,
    className: PropTypes.string
}

Hero.defaultProps = {
    backgroundImageSizes: [],
    backgroundImage: '',
    backgroundOpacity: 1,
    small: false,
    curveFill: '#ffffff',
    alt: '',
    simple: false,
    className: ''
}

export default withStyles(styles)(Hero);