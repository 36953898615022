import baseApi from '../api';

export default class pageAPI extends baseApi {
    async getEvents(page=1, filters = {}) {
        if (page !== 1) {
            filters['page'] = page
        }

        let queryString = Object.keys(filters).map(key => key + '=' + filters[key]).join('&');

        queryString = queryString ? `?${queryString}` : '';

        const pages = await this.fetchFromAPI(
            `/events/${queryString}`,
            'get-events',
            'Get list of Events',
            'get'
        );

        return pages;
    }


    async getEvent(eventId, useSlug=false) {
        if (useSlug) return await this.getEvents(1, {slug: eventId})

        const event = await this.fetchFromAPI(
            `/events/${eventId}/`,
            'get-event',
            'Get Single Event',
            'get'
        );

        return event;
    }
}