import {
	REQUEST_STATS,
	RECEIVED_STATS
} from './actions';

const initialState = {
	isUpdating: false,
	loaded: false,
	memberCount: 0,
	studentMemberCount: 0,
	partneredUniversityCount: 0
};


export const reducer = (state = initialState, action) => {
	switch (action.type) {
		case REQUEST_STATS:
			return Object.assign({}, state, {
				...state,
				isUpdating: true
			});
		case RECEIVED_STATS:
			return Object.assign({}, state, {
				isUpdating: false,
				loaded: true,
				memberCount: action.memberCount,
				studentMemberCount: action.studentMemberCount,
				partneredUniversityCount: action.partneredUniversityCount
			});
		default:
			return state;
	}
}
