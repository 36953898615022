import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { renderRoutes } from 'react-router-config';

import './FontAwesome';
import Header from './components/Header';
import Footer from "./components/Footer";

class App extends Component {
	getChildContext() {
		return this.props.context;
	}

	render() {
		return (
			<div>
				<Header />
				{renderRoutes(this.props.route.routes)}
				<Footer/>
			</div>
		);
	}
};

App.propTypes = {
	context: PropTypes.shape({
		insertCss: PropTypes.func.isRequired,
	}).isRequired,
	route: PropTypes.object
};

App.childContextTypes = {
	insertCss: PropTypes.func.isRequired,
};

export default {
	component: App
};
