import React, {Component, Fragment} from 'react';
import { connect } from 'react-redux';
import {Picture} from 'react-responsive-picture';
import {convertFromRaw} from 'draft-js';

import {
    getUniversities,
    getUniversity,
    getAcademics,
    getInstructors,
    getResearch,
    getEvents
} from '../data/universities/actions';
import Hero from '../components/Hero';


const universityEnhancer = (WrappedComponent) => {
    class University extends Component {
        constructor(props) {
            super(props);

            this.getSingleUniversity = this._getSingleUniversity.bind(this);
            this.pageUrl = this.props.location
                ? `https://www.aanzp.com.au${this.props.location.pathname}`
                : 'https://www.aanzp.com.au';
        }

        _getSingleUniversity(slug='') {
            const universitySlug = slug ? slug : this.props.match.params.universitySlug;

            this.props.getUniversity(universitySlug, true);
        }

        getHero(university, title = false, backgroundOpacity = 1 , curveFill = '#ffffff') {
            if (university && university.featuredImage) {
                const heroImage = university.featuredImage;
                const sizes = heroImage ? heroImage.sizes : [];
                const alt = heroImage ? heroImage.alt : '';

                const heroSizes = sizes.filter(item => {
                    return item.size.startsWith('hero');
                });

                return (
                    <Hero
                        backgroundImageSizes={heroSizes}
                        backgroundOpacity={backgroundOpacity}
                        curveFill={curveFill}
                        alt={alt}
                    >
                        {title && <h1 style={{textTransform: 'capitalize'}}>{title}</h1>}
                    </Hero>
                );
            }
        }

        getImage(university, size, returnObject=true) {
            const image = university ? university.featuredImage : false;

            if (image) {
                const imageItem = image.sizes.find(image => {
                    return image.size === size;
                });

                return returnObject ? imageItem : imageItem.url;
            }

            return returnObject ? {} : '';
        }

        getFeaturedImage(university, sizes) {
            const featuredImage = university ? university.featuredImage : false;

            if (featuredImage) {
                const sources = featuredImage.sizes.filter(image => {
                    return image.size.startsWith('card');
                }).map(image => (`${image.url} ${image.width}w`));

                const imgSrc = sources.join(', ');

                return <Picture src={imgSrc} alt={featuredImage.alt} sizes={sizes}/>
            }
        }

        getExcerpt(university) {
            if (university.about) {
                const plainText = university.about.replace(/(\r\n|\n|\r)/gm, " ");

                let trimmed = plainText.substring(0, 130);
                trimmed = trimmed.substr(0, Math.min(trimmed.length, trimmed.lastIndexOf(" ")));

                return `${trimmed}...`;
            }
        }

        getLink(university) {
            return `/universities/${university.slug}/`;
        }

        render() {
            return (
                <WrappedComponent
                    isUpdating={this.props.isUpdating}
                    singleUniversity={this.props.university}
                    universities={this.props.universities}
                    getUniversity={this.getSingleUniversity}
                    getUniversities={this.props.getUniversities}
                    getFeaturedImage={this.getFeaturedImage}
                    getHero={this.getHero}
                    getImage={this.getImage}
                    getExcerpt={this.getExcerpt}
                    getLink={this.getLink}
                    getAcademics={this.props.getAcademics}
                    getInstructors={this.props.getInstructors}
                    getResearch={this.props.getResearch}
                    getEvents={this.props.getEvents}
                    pageUrl={this.pageUrl}
                />
            )
        }
    }

    return connect(mapStateToProps, mapDispatchToProps)(University);
};

const mapStateToProps = (state, ownProps) => {
    const {data: {universities: {currentUniversity: university, universities, isUpdating} } } = state;

    return {
        isUpdating,
        university,
        universities
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getUniversities: (page=1, filters={}) => dispatch(getUniversities(page, filters)),
        getUniversity: (universityId, useSlug=false) => dispatch(getUniversity(universityId, useSlug)),
        getAcademics: (universityId) => dispatch(getAcademics(universityId)),
        getInstructors: (universityId) => dispatch(getInstructors(universityId)),
        getResearch: (universityId, pageSize= false) => dispatch(getResearch(universityId, pageSize)),
        getEvents: (universityId) => dispatch(getEvents(universityId))
    }
}

export const loadSingleUniversity = (slug='') => {
    return (store, pathKeys, matchPath) => {
        const universitySlug = slug ? slug : pathKeys.universitySlug;

        return store.dispatch(getUniversity(universitySlug, true));
    }
}

export const loadUniversities = (page=1, filters={}) => {
    return (store) => {
        return store.dispatch(getUniversities(page, filters));
    }
}

export default universityEnhancer;
