import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {convertFromRaw} from 'draft-js';
import {stateToHTML} from 'draft-js-export-html';

import styles from './styles.scss';

const DraftToHtml = (props) => {
    const convertToHTML = () => {
        const options = {
            entityStyleFn: entity => {
                const entityType = entity.get('type').toLowerCase();
                if (entityType === 'link') {
                    const data = entity.getData();

                    return {
                        element: 'a',
                        attributes: {
                            href: data.url,
                            className: data.isButton ? 'button' : 'link'
                        }
                    }
                }
            },
            blockStyleFn: block => {
                switch (block.getType()) {
                    case 'blockquote':
                        return {attributes: {className: 'RichEditor-blockquote'}};
                    case 'left':
                        return {attributes: {className: 'text-left'}};
                    case 'center':
                        return {attributes: {className: 'text-center'}};
                    case 'right':
                        return {attributes: {className: 'text-right'}};
                    default:
                        return null;
                }
            },
        };


        if (props.rawContentState) {
            const contentState = convertFromRaw(JSON.parse(props.rawContentState));
            return stateToHTML(contentState, options);
        }

        return '';
    };

    return <div styleName="content" dangerouslySetInnerHTML={{__html: convertToHTML()}}/>
};

DraftToHtml.propTypes = {
    rawContentState: PropTypes.string.isRequired
}

export default withStyles(styles)(DraftToHtml);