import React from 'react';
import withStyles from "isomorphic-style-loader/lib/withStyles";
import Section from '../../../../components/Section';
import Card from '../../../../components/CardStandard';

import styles from "./styles.scss";


const Academics = (props) => {
	const { university, getFeaturedImage } = props;

	const renderAcademics = () => {
		return university.fullTimeAcademics.map(academic => {
			return (
				<div key={academic.id} styleName="feed__item">
					<Card
						title={academic.title}
						subtitle={academic.subtitle}
						img={getFeaturedImage(academic)}
						unstyled={true}
					/>
				</div>
			)
		})
	};

	if ( university && university.fullTimeAcademics) {
		return (
			<Section id="fulltimeAcademics" styleName="section-alt" tag="section" wrap={true}>
                <div styleName="section-header">
                    <h2 styleName="university-title">Full Time Academics</h2>
                </div>
				<div styleName="feed">
					<div styleName="feed__row">
						{renderAcademics()}
					</div>
				</div>
            </Section>
		);
	}

	return null;
};

export default withStyles(styles)(Academics);
