import React, {Component, Fragment} from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import Slider from "react-slick";
import moment from 'moment';

import eventEnhancer from '../../../../events/eventEnhancer';
import styles from './styles.scss';
import carouselSettings from '../../carouselSettings';
import CardEvent from "../../../../components/CardEvent";


class UpcomingEvents extends Component {
    componentDidMount() {
        this.props.getEvents(1, {university__isnull: true, page_size: 3})
    }

    renderEvents() {
        return this.props.events.data.slice(0, 3).map(event => {
            const imageSizes = `(min-width: 1200px) 338px,
            (min-width: 992px) 278px, 
            (min-width: 768px) 358px, 
            (min-width: 576px) 378px ,100vw`;

            const eventDate = moment(event.date);
            const day = eventDate.format('DD');
            const month = eventDate.format('MMM');
            const year = eventDate.format('YYYY');

            return (
                <div key={event.id} styleName="event">
                    <CardEvent
                        title={event.title}
                        img={this.props.getFeaturedImage(event, imageSizes)}
                        day={day}
                        month={month}
                        year={year}
                        link={this.props.getLink(event)}
                    >
                        {this.props.getExcerpt(event)}
                    </CardEvent>
                </div>
            );
        })
    }

    render() {
        return (
            <Slider {...carouselSettings}>
                {this.renderEvents()}
            </Slider>
        )
    }
}

export default eventEnhancer(withStyles(styles)(UpcomingEvents));