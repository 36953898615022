import React, {Component, Fragment} from 'react';
import withStyles from "isomorphic-style-loader/lib/withStyles";

import withPageSubscription, {loadData} from '../withPageSubscription';
import Section from '../../components/Section';
import EventFeed from './components/EventFeed';
import styles from './styles.scss';

class Events extends Component {
    render() {
        const {page} = this.props;

        return (
           <Fragment>
               {this.props.getHero(page.title, 0.6)}
               <Section
                   styleName="events"
                   tag="section"
                   wrap={false}
                   isTop={true}
                   isBottom={true}
                   innerClassName="events-inner"
               >
                   <div styleName="events__container">
                        <EventFeed/>
                   </div>
               </Section>
           </Fragment>
        )
    }
}

export default {
    loadData: loadData(),
	component: withPageSubscription(withStyles(styles)(Events))
}

