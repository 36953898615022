import React, {useEffect, Fragment} from 'react';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import withStyles from "isomorphic-style-loader/lib/withStyles";
import moment from 'moment';

import postEnhancer from '../../../../posts/postEnhancer';
import styles from './styles.scss';


const FeedItems = (props) => {
    const {posts, getPosts, getExcerpt, getLink, getPostFeaturedImage} = props;

    useEffect(() => {
        getPosts('news', 1);
    }, []);


    const renderNewsItems = () => {
        const newsPosts = 'news' in posts.types ? posts.types.news.data : [];

        return newsPosts.map(newsItem => {
            const date = moment(newsItem.created).format('MMMM Do YYYY');
            return (
                <div key={newsItem.id} styleName="news-item">
                    <div styleName="news-item__image">
                        {getPostFeaturedImage(newsItem)}
                    </div>
                    <div styleName="news-item__content">
                        <h3 styleName="news-item__title">{newsItem.title}</h3>
                        <span styleName="news-item__date">{date}</span>
                        <p styleName="news-item__excerpt">
                            {getExcerpt(newsItem, 255)}
                        </p>
                        <Link styleName="news-item__more" to={getLink(newsItem)}>
                            Read More <FontAwesomeIcon icon={['far', 'long-arrow-right']}/>
                        </Link>
                    </div>
                </div>
            )
        })
    }

    return (
        <Fragment>
            {renderNewsItems()}
        </Fragment>
    )
};

export default postEnhancer(withStyles(styles)(FeedItems));