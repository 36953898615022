import React, { Component } from 'react';
import withStyles from "isomorphic-style-loader/lib/withStyles";

import DraftToHtml from '../../../../components/DraftToHtml';
import styles from './styles.scss';
import {Col} from "reactstrap";
import postEnhancer from "../../../../posts/postEnhancer";


class SingleProcedure extends Component {
    componentDidMount() {
        const { singlePost, match, getSinglePost } = this.props;

        if (!singlePost.id) {
            const slug = match.params.procedureSlug;
            getSinglePost('common-procedures', slug);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {singlePost, setHeroImage, setTitle} = this.props;

        if (prevProps.singlePost.id !== this.props.singlePost.id) {
            setHeroImage(singlePost.featuredImage);
            setTitle(singlePost.title);
        }
    }

    render() {
        const {
            match,
            getSinglePost,
            singlePost,
            setHeroImage,
        } = this.props;

        return (
            <Col xs="12" md="9" lg="8">
                <DraftToHtml rawContentState={singlePost.content}/>
            </Col>
        );
    }
}

export default postEnhancer(withStyles(styles)(SingleProcedure));


