import React from 'react';
import { FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import PropTypes from 'prop-types';

import styles from './styles.scss';

const EducationItem = (props) => {
    const universityImage = () => {
        if (props.image) {
            return (<image src={props.image} alt={props.universityName} />);
        } else {
            return (
                <div styleName="university-placeholder">
                    <div styleName="university-placeholder__icon">
                        <FontAwesomeIcon icon={['fas', 'university']}/>
                    </div>
                </div>
            )
        }
    };

    return (
        <div styleName="education">
            <div styleName="education__image">
                {universityImage()}
            </div>
            <div styleName="education__details">
                <h5 styleName="education__uni">{props.universityName}</h5>
                <p styleName="education__course">{props.universityCourse}</p>
                <p styleName="education__completion">
                    <FontAwesomeIcon icon={['fal', 'graduation-cap']}/> {props.completionYear}
                </p>
            </div>
        </div>
    )
}

EducationItem.propTypes = {
    universityName: PropTypes.string,
    universityCourse: PropTypes.string,
    completionYear: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    image: PropTypes.string
};

EducationItem.defaultProps = {
    universityName: '',
    universityCourse: '',
    completionYear: '',
    image: ''
};

export default withStyles(styles)(EducationItem);