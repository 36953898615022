import APIBase from '../api';

export default class PagesAPI extends APIBase {
    /**
     * Retrieves pages, optionally filtered down
     * @param page
     * @param filters
     * @returns {Promise<*|*>}
     */
    async getPages(page=1, filters = {}) {
        if (page !== 1) {
            filters['page'] = page
        }

        let queryString = Object.keys(filters).map(key => key + '=' + filters[key]).join('&');

        queryString = queryString ? `?${queryString}` : '';

        const pages = await this.fetchFromAPI(
            `/pages/${queryString}`,
            'get-pages',
            'Get list of Pages',
            'get'
        );

        return pages;
    }

}
