import { combineReducers } from 'redux';

import { reducer as prosthodontistsReducer } from './prosthodontists/reducer';
import { reducer as pageReducer } from './pages/reducer';
import { reducer as eventReducer } from './events/reducer';
import { reducer as postReducer } from './posts/reducer';
import { reducer as universityReducer } from './universities/reducer';
import { reducer as statsReducer } from './stats/reducer';
import { reducer as formReducer } from './forms/reducer';

export default combineReducers({
	stats: statsReducer,
	prosthodontists: prosthodontistsReducer,
	pages: pageReducer,
	events: eventReducer,
	posts: postReducer,
	universities: universityReducer,
	forms: formReducer
});
