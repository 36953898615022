import {
	CREATE_NEW_UNIVERSITY,
	NETWORK_REQUEST_IN_PROGRESS,
	SET_CURRENT_UNIVERSITY,
	RESET_UNIVERSITY_DID_UPDATE,
	REQUEST_PAGINATED_UNIVERSITIES,
	RECEIVE_PAGINATED_UNIVERSITIES,
	RECEIVE_UNIVERSITY_SUGGESTIONS,
	CLEAR_UNIVERSITY_SUGGESTIONS,
	NETWORK_ERROR,
	RECEIVE_ACADEMICS,
	RECEIVE_INSTRUCTORS, RECEIVE_RESEARCH, RECEIVE_EVENTS
} from './actions';


const initialState = {
	isUpdating: false,
	currentUniversity: {
		didUpdate: false,
		ready: false,
		id: '',
		slug: '',
		name: '',
		website: '',
		phone: '',
		email: '',
		about: '',
		location: false,
		featuredImage: false,
		fullTimeAcademics: [],
		visitingInstructors: [],
		students: [],
		research: [],
		events: [],
		partnered: false
	},
	universities: {
		isRequesting: false,
		count: 0,
		currentPage: 0,
		next: false,
		previous: false,
		data: []
	},
	autoSuggest: [{title: 'None', id: null}]

}

export const reducer = (state = initialState, action) => {
	switch (action.type) {
		case CREATE_NEW_UNIVERSITY:
			return Object.assign({}, state, {
				...state,
				isUpdating: action.isUpdating,
				ready: false,
				currentUniversity: initialState.currentUniversity
			});
		case NETWORK_REQUEST_IN_PROGRESS:
			return Object.assign({}, state, {
				...state,
				isUpdating: true
			});
		case SET_CURRENT_UNIVERSITY:
			return Object.assign({}, state, {
				...state,
				isUpdating: false,
				currentUniversity: {
					...state.currentUniversity,
					didUpdate: true,
					ready: true,
					id: action.id,
					slug: action.slug,
					name: action.name,
					website: action.website,
					phone: action.phone,
					email: action.email,
					about: action.about,
					location: action.location,
					featuredImage: action.featuredImage,
					partnered: action.partnered,
					students: action.students,
					fullTimeAcademics: [],
					visitingInstructors: [],
					research: [],
					events: [],
				},
			});
		case RESET_UNIVERSITY_DID_UPDATE:
			return Object.assign({}, state, {
				...state,
				isUpdating: false,
				currentUniversity: {
					...state.currentUniversity,
					didUpdate: false
				}
			});
		case REQUEST_PAGINATED_UNIVERSITIES:
			return Object.assign({}, state, {
				...state,
				universities: {
					...state.universities,
					isRequesting: true,
				}
			});
		case RECEIVE_PAGINATED_UNIVERSITIES:
			return Object.assign({}, state, {
				...state,
				universities: {
					isRequesting: false,
					count: action.count,
					currentPage: action.currentPage,
					next: action.next,
					previous: action.previous,
					data: action.data
				}
			});
		case RECEIVE_UNIVERSITY_SUGGESTIONS:
			return Object.assign({}, state, {
				...state,
				autoSuggest: action.results
			});
		case CLEAR_UNIVERSITY_SUGGESTIONS:
			return Object.assign({}, state, {
				...state,
				autoSuggest: []
			});
		case NETWORK_ERROR:
			return Object.assign({}, state, {
				...state,
				universities: {
					...state.universities,
					isRequesting: false,
				}
			});
		case RECEIVE_ACADEMICS:
			return Object.assign({}, state, {
				...state,
				isUpdating: false,
				currentUniversity: {
					...state.currentUniversity,
					fullTimeAcademics: action.academics
				}
			});
		case RECEIVE_INSTRUCTORS:
			return Object.assign({}, state, {
				...state,
				isUpdating: false,
				currentUniversity: {
					...state.currentUniversity,
					visitingInstructors: action.instructors
				}
			});
		case RECEIVE_RESEARCH:
			return Object.assign({}, state, {
				...state,
				isUpdating: false,
				currentUniversity: {
					...state.currentUniversity,
					research: action.research
				}
			});
		case RECEIVE_EVENTS:
			return Object.assign({}, state, {
				...state,
				isUpdating: false,
				currentUniversity: {
					...state.currentUniversity,
					events: action.events
				}
			});
		default:
			return state
	}
}

