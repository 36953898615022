import React, {Component, Fragment} from 'react';
import {Route, Switch, withRouter} from "react-router-dom";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import {Col} from "reactstrap";

import withPageSubscription, {loadData} from "../withPageSubscription";
import Section from '../../components/Section';
import SubNavigation from '../../components/SubNavigation';
import Button from '../../components/Button';

import styles from "./styles.scss";
import StandardContent from './components/StandardContent';
import CommonDentalProcedures from './components/CommonDentalProcedures';
import SingleProcedure from "./components/SingleProcedure";


const testLinks = [
    {id: 1, title: 'Dental Specialists', link: '/patient-resources/dental-specialists'},
    {id: 2, title: 'Common Dental Procedures', link: '/patient-resources/common-dental-procedures'},
    {id: 3, title: 'Your first appointment', link: '/patient-resources/your-first-appointment'},
    {id: 4, title: 'Frequently Asked Questions', link: '/patient-resources/faqs'},
    {id: 5, title: 'External Resources', link: '/patient-resources/external-resources'},
];

class PatientResources extends Component {
    constructor(props) {
        super(props);

        this.state = {
            title: ''
        }

        this.getPage = this._getPage.bind(this);
        this.setTitle = this._setTitle.bind(this);
    }

    componentDidMount() {
        this.setTitle();
    }

    componentDidUpdate(prevProps) {
        if (this.props.page.id !== prevProps.page.id) {
            this.setTitle();
        }
    }

    _getPage() {
       const child = this.props.getChild();
       const page = child ? child : this.props.page;

       return page;
    }

    _setTitle(title='') {
        let newTitle = title;

        if (!title) {
            const page = this.getPage();
            newTitle = page.title;
        }

        this.setState({
            title: newTitle
        });
    }

    render() {
        const page = this.getPage();

        return (
            <Fragment>
                {this.props.getHero(this.state.title, 0.6)}
                <Section>
                    <Col xs="12" md="3">
                        <SubNavigation navigationItems={testLinks} position='left'/>
                    </Col>
                    <Switch>
                        <Route
                            path="/patient-resources/dental-specialists"
                            exact
                            component={(props) => <StandardContent
                                {...props}
                                page={this.props.page}
                                preludeAfter={<Button link="/find-a-prosthodontist">Locate a Specialist</Button>}
                            />}
                        />
                        <Route
                            path="/patient-resources/your-first-appointment"
                            exact
                            component={(props) => <StandardContent {...props} page={this.props.page}/>}
                        />
                        <Route
                            path="/patient-resources/common-dental-procedures"
                            exact
                            component={(props) => <CommonDentalProcedures {...props} page={this.props.page}/>}
                        />
                        <Route
                            path="/patient-resources/common-dental-procedures/:procedureSlug"
                            exact
                            component={(props) => <SingleProcedure {...props} page={this.props.page}
                                                                   setHeroImage={this.props.setHeroImage}
                                                                   setTitle={this.setTitle}
                            />}
                        />
                    </Switch>
                </Section>
            </Fragment>
        )
    }
}

export default {
    loadData: loadData(),
    component: withRouter(withPageSubscription(withStyles(styles)(PatientResources)))
}