import React, {Component, Fragment} from 'react';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {TwitterTimelineEmbed} from 'react-twitter-embed';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import InViewMonitor from 'react-inview-monitor';

import {
    Container,
    Row,
    Col
} from 'reactstrap';

import withPageSubscription, {loadData} from '../withPageSubscription';
import {loadEvents} from '../../events/eventEnhancer';
import {loadPosts} from '../../posts/postEnhancer';

import Hero from './components/Hero';
import Section from '../../components/Section';
import UpcomingEvents from './components/UpcomingEvents';
import LatestNews from './components/LatestNews';

import DraftToHtml from '../../components/DraftToHtml';
import mapMarker from './map-marker-icon.svg';
import memberIcon from './member-icon.svg';
import styles from './styles.scss';


const LinkOrAnchor = (props) => {
    const {to, children} = props;

    if (to.startsWith('http') || to.startsWith('https') || to.startsWith('www')) {
        return (
            <a href={to} target="_blank" rel="noreferrer nofollow" styleName="masonry__panel-content">
                {children}
            </a>
        );
    } else {
        return (
            <Link to={to} styleName="masonry__panel-content">
                {children}
            </Link>
        )
    }
};

const StyledLinkOrAnchor = withStyles(styles)(LinkOrAnchor);

class Home extends Component {
    render() {
        const featuredLinks = this.props.getField('home-featured-links');

        return (
            <Fragment>
                <Hero items={this.props.getField('slider')}/>
                <section styleName="about">
                    <Container>
                        <Row>
                            <Col xs="12" md="8" lg="7">
                                <InViewMonitor
                                    classNameInView="animated fadeIn"
                                >
                                    <h1>{this.props.page.title}</h1>
                                    <DraftToHtml rawContentState={this.props.page.content}/>
                                </InViewMonitor>
                            </Col>
                            <Col xs="12" md="4" lg="5" styleName="quick-links">
                                <div styleName="quick-link">
                                    <div styleName="quick-link__icon">
                                        <InViewMonitor classNameInView="animated bounceIn">
                                            <img src={mapMarker} alt="find a specialist"/>
                                        </InViewMonitor>
                                    </div>
                                    <div styleName="quick-link__text">
                                        <h4 styleName="quick-link__title">FIND A SPECIALIST</h4>
                                        <p className="d-none d-md-block">
                                            Search for a Specialist Prosthodontist near you.
                                        </p>
                                        <Link to='/find-a-prosthodontist'>
                                            Search your area <FontAwesomeIcon
                                            className="d-none d-md-inline-block"
                                            icon={['far', 'long-arrow-right']}
                                        />
                                        </Link>
                                    </div>
                                </div>
                                <div styleName="quick-link">
                                    <div styleName="quick-link__icon">
                                        <InViewMonitor classNameInView="animated bounceIn">
                                            <img src={memberIcon} alt="member login"/>
                                        </InViewMonitor>
                                    </div>
                                    <div styleName="quick-link__text">
                                        <h4 styleName="quick-link__title">MEMBER LOGIN</h4>
                                        <a href='https://members.aanzp.com.au' target="_blank">
                                            Login Here <FontAwesomeIcon
                                            className="d-none d-md-inline-block"
                                            icon={['far', 'long-arrow-right']}
                                        />
                                        </a>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <Section tag="section" wrap={false} isTop={true}>
                    <div styleName="masonry">
                        <div styleName="masonry__col">
                            <div styleName="masonry__panel">
                                {featuredLinks[0] &&
                                <StyledLinkOrAnchor to={featuredLinks[0].link}>
                                    <div styleName="masonry__panel-image"
                                         style={
                                             {backgroundImage: `url(${this.props.getImage(featuredLinks[0].image, 'medium_large', false)})`}
                                         }
                                    />
                                    <div styleName="masonry__panel-title">
                                        {featuredLinks[0].title}
                                    </div>
                                </StyledLinkOrAnchor>
                                }
                            </div>
                            <div styleName="masonry__panel">
                                {featuredLinks[2] &&
                                <StyledLinkOrAnchor to={featuredLinks[2].link}>
                                    <div styleName="masonry__panel-image"
                                         style={
                                             {backgroundImage: `url(${this.props.getImage(featuredLinks[2].image, 'medium_large', false)})`}
                                         }
                                    />
                                    <div styleName="masonry__panel-title">
                                        {featuredLinks[2].title}
                                    </div>
                                </StyledLinkOrAnchor>
                                }
                            </div>
                        </div>
                        <div styleName="masonry__col">
                            <div styleName="masonry__panel">
                                {featuredLinks[1] &&
                                <StyledLinkOrAnchor to={featuredLinks[1].link}>
                                    <div styleName="masonry__panel-image"
                                         style={
                                             {backgroundImage: `url(${this.props.getImage(featuredLinks[1].image, 'medium_large', false)})`}
                                         }
                                    />
                                    <div styleName="masonry__panel-title">
                                        {featuredLinks[1].title}
                                    </div>
                                </StyledLinkOrAnchor>
                                }
                            </div>
                            <div styleName="masonry__panel">
                                <div styleName="masonry__panel-content twitter-widget">
                                    <h3 styleName="twitter-widget__title">
                                        AANZP Latest <FontAwesomeIcon icon={['fab', 'twitter']}/>
                                    </h3>
                                    <div styleName="twitter-widget__tweets">
                                        <TwitterTimelineEmbed
                                            sourceType="profile"
                                            screenName="TheAANZP"
                                            autoHeight
                                            noHeader
                                            noFooter
                                            transparent
                                            theme="dark"
                                            linkColor="#ffffff"
                                        />
                                    </div>
                                    <footer styleName="twitter-widget__footer">
                                        <a href="" target="_blank">
                                            <FontAwesomeIcon icon={['fab', 'twitter']}/>
                                        </a>
                                        <a href="" target="_blank">
                                            <FontAwesomeIcon icon={['fab', 'facebook']}/>
                                        </a>
                                    </footer>
                                </div>
                            </div>
                            <div styleName="masonry__panel">
                                {featuredLinks[3] &&
                                <StyledLinkOrAnchor to={featuredLinks[3].link}>
                                    <div styleName="masonry__panel-image"
                                         style={
                                             {backgroundImage: `url(${this.props.getImage(featuredLinks[3].image, 'medium_large', false)})`}
                                         }
                                    />
                                    <div styleName="masonry__panel-title">
                                        {featuredLinks[3].title}
                                    </div>
                                </StyledLinkOrAnchor>
                                }
                            </div>
                            <div styleName="masonry__panel">
                                {featuredLinks[4] &&
                                <StyledLinkOrAnchor to={featuredLinks[4].link}>
                                    <div styleName="masonry__panel-image"
                                         style={
                                             {backgroundImage: `url(${this.props.getImage(featuredLinks[4].image, 'medium_large', false)})`}
                                         }
                                    />
                                    <div styleName="masonry__panel-title">
                                        {featuredLinks[4].title}
                                    </div>
                                </StyledLinkOrAnchor>
                                }
                            </div>
                        </div>
                    </div>
                </Section>
                <Section
                    tag="section"
                    styleName="events"
                    wrap={false}
                    title={<Fragment>Upcoming Events <FontAwesomeIcon icon={['far', 'calendar-alt']}/></Fragment>}
                    titleHr={true}
                    titleCTA={<Link to="/events">View All <FontAwesomeIcon icon={['far', 'long-arrow-right']}/></Link>}
                >
                    <UpcomingEvents/>
                </Section>
                <Section
                    tag="section"
                    styleName="latest-news"
                    wrap={false}
                    isBottom={true}
                    paddingTop={false}
                    title={<Fragment>Latest News <FontAwesomeIcon icon={['far', 'newspaper']}/></Fragment>}
                    titleHr={true}
                    titleCTA={<Link to="/news">View All <FontAwesomeIcon icon={['far', 'long-arrow-right']}/></Link>}
                >
                    <LatestNews/>
                </Section>
            </Fragment>
        );
    }
}

const loadRequiredData = () => {
    return async (store, pathKeys, matchPath) => {
        const loadPageData = loadData('home');
        const loadEventData = loadEvents(1, {university__isnull: true, page_size: 3});
        const loadPostData = loadPosts('news', 1, {page_size: 3});

        await loadPageData(store, pathKeys, matchPath);
        await loadEventData(store);
        return await loadPostData(store);
    }
}

export default {
    loadData: loadRequiredData(),
    component: withPageSubscription(withStyles(styles)(Home), 'home')
}