// Entry point for Client side application
import '@babel/polyfill';
import React from 'react';
import ReactDom from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import { renderRoutes } from 'react-router-config';
import axios from 'axios';

import Routes from '../shared/Routes';
import reducers from './reducers';
import registerServiceWorker from './registerServiceWorker';
import ScrollToTop from './ScrollToTop';


const axiosInstance = axios.create({
	baseURL: '/api'
});

const middlewares = [
	thunk.withExtraArgument(axiosInstance),
];

// Log redux store to cosole during development
if (process.env.NODE_ENV === 'development') {
	const { createLogger } =  require('redux-logger');

	const loggerMiddleware = createLogger();

	middlewares.push(loggerMiddleware);
}

// Redux dev tools extension
const composeEnhancers =
	typeof window === 'object' &&
	window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
	window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
		// Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
	}) : compose;

const storeEnhancer = composeEnhancers(
	applyMiddleware(...middlewares)
	// other store enhancers if any
);

const store = createStore(
	reducers,
	window.INITIAL_STATE,
	storeEnhancer
);

const context = {
	// Enables critical path CSS rendering
  	// https://github.com/kriasoft/isomorphic-style-loader
	insertCss: (...styles) => {
		// console.log(styles);
		// eslint-disable-next-line no-underscore-dangle
		const removeCss = styles.map(x => x._insertCss());
		return () => {
			removeCss.forEach(f => f());
		};
	}
}

ReactDom.hydrate(
	<Provider store={store}>
		<BrowserRouter>
			<ScrollToTop>
				<div>{renderRoutes(Routes, { context })}</div>
			</ScrollToTop>
		</BrowserRouter>
	</Provider>
	, document.querySelector('#root')
);

registerServiceWorker();
