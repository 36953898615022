import React from 'react';
import withStyles from "isomorphic-style-loader/lib/withStyles";
import {Form, Field} from "formik";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import styles from '../styles.scss';
import {Col, CustomInput, FormGroup, Input, Button, Row} from "reactstrap";

const displayName = 'Contact Form';

const initialValues = {
    firstName: '',
    lastName: '',
    subject: '',
    email: '',
    message: '',
};

const ContactForm = (props) => {
    const {
        values,
        touched,
        errors,
        handleBlur,
        setFieldValue,
        isSubmitting,
        submitForm,
        initialState,
        getPageSuggestions,
        clearPageSuggestions,
        pageSuggestions,
        submissionData
    } = props;

    const getFieldError = (fieldName) => {
        if (errors[fieldName] && touched[fieldName]) {
            return (
                <div className="invalid-feedback">
                    {errors[fieldName]}
                </div>
            );
        }

        return null;
    };

    const renderSubmissionErrors = () => {
        if (submissionData.errors && submissionData.formName === 'contact') {
            const errorData = submissionData.errors.data;
            const errors = [];

            Object.entries(errorData).forEach(([key, value]) => {

               if (Array.isArray(value)) {
                   value.map((error) => {
                       errors.push(error);
                   });
               }

            });

            return errors.map((error, index) => {
                return (
                    <li key={index}>{error}</li>
                );
            });
        }

        return null;
    };


    if (submissionData.data && submissionData.formName === 'contact') {
       return (
           <div>
               <h4>Thanks</h4>
               <p>Thanks for contacting us. We will be in touch shortly.</p>
               <p>Kind Regards, AANZP</p>
           </div>
       )
    } else {
        return (
            <Form styleName="form">
                <Row form>
                    <Col xs={12} lg={6}>
                        <FormGroup>
                            <Field
                                styleName="form__input"
                                className="form-control"
                                name="firstName"
                                id="firstName"
                                placeholder="First Name*"
                            />
                            {getFieldError('firstName')}
                        </FormGroup>
                    </Col>
                    <Col xs={12} lg={6}>
                        <Field
                            styleName="form__input"
                            className="form-control"
                            name="lastName"
                            id="lastName"
                            placeholder="Last Name*"
                        />
                        {getFieldError('lastName')}
                    </Col>
                </Row>
                <FormGroup>
                    <Field
                        styleName="form__select"
                        className="custom-select"
                        id="subject"
                        name="subject"
                        as="select"
                    >
                        <option value="">Subject*</option>
                        <option value="General Enquiry">General Enquiry</option>
                        <option value="Membership Enquiry">Membership Enquiry</option>
                        <option value="Billing Enquiry">Billing Enquiry</option>
                        <option value="Technical Issue">Technical Issue</option>
                        <option value="Other">Other</option>
                    </Field>
                    {getFieldError('subject')}
                </FormGroup>
                <FormGroup>
                    <Field
                        styleName="form__input"
                        className="form-control"
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Your Email*"
                    />
                    {getFieldError('email')}
                </FormGroup>
                <FormGroup>
                    <Field
                        styleName="form__textarea"
                        className="form-control"
                        name="message"
                        id="message"
                        placeholder="Message*"
                        as="textarea"
                    />
                    {getFieldError('message')}
                </FormGroup>
                {submissionData.errors
                && <div className="alert alert-danger" role="alert">
                    <ul className="list-unstyled">{renderSubmissionErrors()}</ul>
                </div>
                }
                <Button type="submit" color="primary">
                    Send
                    {isSubmitting
                    && <span> <FontAwesomeIcon icon={['far', 'circle-notch']} spin /></span>
                    }
                </Button>
            </Form>
        )
    }
};

export default withStyles(styles)(ContactForm);
export {displayName, initialValues}