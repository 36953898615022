import {
    REQUEST_PROSTHODONTIST,
    RECEIVE_PROSTHODONTIST,
    RECEIVE_PROSTHODONTIST_SUGGESTIONS,
    CLEAR_PROSTHODONTIST_SUGGESTIONS,
} from './actions';

const initialState = {
    loaded: false,
    isFetching: false,
    prosthodontist: {
        id: '',
        firstName: '',
        lastName: '',
        bio: '',
        education: [],
        clinics: [],
        slug: ''
    },
    autoSuggest: [{title: 'None', id: null, slug: ''}]
}

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case REQUEST_PROSTHODONTIST:
            return Object.assign({}, state, {
                isFetching: true
            });
        case RECEIVE_PROSTHODONTIST:
            return Object.assign({}, state, {
                loaded: action.loaded,
                isFetching: false,
                prosthodontist: {
                    id: action.id,
                    firstName: action.firstName,
                    lastName: action.lastName,
                    profileImage: action.profileImage,
                    biography: action.biography,
                    education: action.education,
                    clinics: action.clinics
                }
            });
        case RECEIVE_PROSTHODONTIST_SUGGESTIONS:
            return Object.assign({}, state, {
                ...state,
                autoSuggest: action.results
            });
        case CLEAR_PROSTHODONTIST_SUGGESTIONS:
            return Object.assign({}, state, {
                ...state,
                autoSuggest: []
            });
        default:
            return state
    }
}
