import API from '../api';

export const REQUEST_PROSTHODONTIST = 'REQUEST_PROSTHODONTIS';
export const RECEIVE_PROSTHODONTIST = 'RECEIVE_PROSTHODONTIST';
export const CLEAR_PROSTHODONTIST_SUGGESTIONS = 'CLEAR_PROSTHODONTIST_SUGGESTIONS';
export const RECEIVE_PROSTHODONTIST_SUGGESTIONS = 'RECEIVE_PROSTHODONTIST_SUGGESTIONS'

const clearProsthodontistSuggestions = () => ({
    type: CLEAR_PROSTHODONTIST_SUGGESTIONS
});

function requestProsthodontists() {
    return {
        type: REQUEST_PROSTHODONTIST
    }
}

function receiveProsthodontistSuggestions(data) {
    const results = data.results;

    return {
        type: RECEIVE_PROSTHODONTIST_SUGGESTIONS,
        results: results
    }
}

function receiveProsthodontist(data) {
    const {
        id,
        first_name: firstName,
        last_name: lastName,
        slug,
        profile: {
            biography,
            clinics,
            education,
            profile_image: profileImage
        }

    } = data;

    return {
        type: RECEIVE_PROSTHODONTIST,
        loaded: true,
        id,
        firstName,
        lastName,
        profileImage,
        biography,
        clinics,
        education,
        slug
    }

}

function getProsthodontist(slug) {
    return async(dispatch, getState, api) => {
        const dataAPI = new API(api, dispatch);

        dispatch(requestProsthodontists());

        const json = await dataAPI.getProsthodontistBySlug(slug);

        dispatch(receiveProsthodontist(json.data));

        return Promise.resolve();
    }
}

function getProsthodontistSuggestions(nameContains, exclude=null) {
    return async(dispatch, getState, api) => {
        const dataAPI = new API(api);

        let filter = {
            name: nameContains
        };

        if (exclude) filter['exclude'] = exclude;

        const json = await dataAPI.getProsthodontists(1, filter);

        return dispatch(receiveProsthodontistSuggestions(json.data));
    }
}

export { getProsthodontist, getProsthodontistSuggestions, clearProsthodontistSuggestions };