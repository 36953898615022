import API from "./api";

export const REQUESTING_PAGE = 'REQUESTING_PAGE';
export const RECEIVED_PAGE = 'RECEIVED_PAGE';
export const SAVE_PAGE_TO_STATE = 'SAVE_PAGE_TO_STATE';

const Page = (data) => {
    const {
        id,
        status,
        slug,
        created_on: created,
        updated_on: lastUpdated,
        title,
        subtitle,
        content,
        prelude,
        parent,
        parent_verbose: parentVerbose,
        children,
        is_home: isHome,
        hero_image: heroImage,
        featured_image: featuredImage,
        meta,
    } = data;
    return {
        id,
        status,
        slug,
        created,
        lastUpdated,
        title,
        subtitle,
        content,
        prelude,
        parent,
        parentVerbose,
        children,
        isHome,
        heroImage,
        featuredImage,
        meta
    }
}

const requestingPage = () => ({
    type: REQUESTING_PAGE
})

const receivedPage = (page) => ({
        type: RECEIVED_PAGE,
        page
});

const savePageToState = (loadedPages, page) => {

    loadedPages.push(page);

    if (loadedPages.length > 15) {
        loadedPages.shift()
    }

    return {
        type: SAVE_PAGE_TO_STATE,
        loadedPages,
    }
};

const getPageFromState = (loadedPages, slug) => {

    if (slug === 'home') {
        return loadedPages.find( page => page.isHome === true);
    } else {
        return loadedPages.find( page => page.slug === slug);
    }
}


function getPageBySlug(pageSlug, forceUpdate=false) {
    return async(dispatch, getState, api) => {
        const {data: {pages: {loadedPages}}} = getState();

        dispatch(requestingPage());

        let page = forceUpdate ? false : getPageFromState(loadedPages, pageSlug);

        if (!page) {
            const dataAPI = new API(api);
            let filter = {};

            pageSlug === 'home' ? filter.is_home = true : filter.slug = pageSlug;

            const json = await dataAPI.getPages(1, filter);

            // TODO: Check for errors, display message

            page = Page(json.data.results[0]);

            if (forceUpdate) {
                dispatch(savePageToState([], page));
            } else {
                dispatch(savePageToState(loadedPages, page));
            }

        }

        return dispatch(receivedPage(page));

        //return Promise.resolve();
    }
}

export {getPageBySlug};
