import React from 'react';
import withStyles from "isomorphic-style-loader/lib/withStyles";
import Section from '../../../../components/Section';

import styles from "./styles.scss";
import Card from "../../../../components/CardStandard";


const Students = (props) => {
	const { university } = props;

	const renderStudents= () => {
		return university.students.map(student => {
			return (
				<div key={student.id} styleName="feed__item">
					<Card
						title={`${student.first_name} ${student.last_name}`}
						subtitle={`Year ${student.current_year}`}
						img={<img src={student.profile_image} alt={student.first_name} />}
						unstyled={true}
					/>
				</div>
			)
		})
	};

	if (university && university.students) {
		return (
			<Section id="currentStudents" styleName="section-alt" tag="section" wrap={true}>
                <div styleName="section-header">
                    <h2 styleName="university-title">Current Students</h2>
                </div>
				<div styleName="feed">
					<div styleName="feed__row">
						{ renderStudents() }
					</div>
				</div>
            </Section>
		);
	}

	return null;
};

export default withStyles(styles)(Students);