import React from 'react';
import withStyles from "isomorphic-style-loader/lib/withStyles";
import Section from '../../../../components/Section';

import styles from "./styles.scss";
import Card from '../../../../components/CardStandard';


const Instructors = (props) => {
	const { university, getFeaturedImage } = props;

	const renderInstructors = () => {
		return university.visitingInstructors.map(instructor => {
			return (
				<div key={instructor.id} styleName="feed__item">
					<Card
						title={instructor.title}
						subtitle={instructor.subtitle}
						img={getFeaturedImage(instructor)}
						unstyled={true}
					/>
				</div>
			)
		})
	}

	if (university && university.visitingInstructors) {
		return (
			<Section id="visitingInstructors" styleName="section-alt" tag="section" wrap={true}>
                <div styleName="section-header">
                    <h2 styleName="university-title">Visiting Instructors</h2>
					<div styleName="feed">
					<div styleName="feed__row">
						{renderInstructors()}
					</div>
				</div>
                </div>
            </Section>
		);
	}

	return null;
};

export default withStyles(styles)(Instructors);