import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import {Container, Row, Col} from 'reactstrap';

import styles from './styles.scss';
import contactImage from './contact.jpg';
import aboutImage from './about.jpg';
import communityImage from './community.jpg';


const RelatedLinks = (props) => {
    return (
        <div styleName="related-links" className={props.marginTop ? 'margin-top' : ''}>
            <Container>
                <Row>
                    <Col xs="12" lg={{size: 10, offset: 1}}>
                        <Row>
                            <Col xs="12" md="4" styleName="related-link-item">
                                <div>
                                    <div
                                        styleName="related-link-content-container"
                                        style={{backgroundImage: `url(${props.left.image})`}}
                                    >
                                        <Link styleName="related-link-content" to={props.left.link}>
                                            <h4 styleName="related-link-label">Contact</h4>
                                        </Link>
                                    </div>
                                </div>
                            </Col>
                            <Col xs="12" md="4" styleName="related-link-item">
                                <div>
                                    <div styleName="related-link-content-container"
                                         style={{backgroundImage: `url(${props.center.image})`}}
                                    >
                                        <Link styleName="related-link-content" to={props.center.link}>
                                            <h4 styleName="related-link-label">About AANZP</h4>
                                        </Link>
                                    </div>
                                </div>
                            </Col>
                            <Col xs="12" md="4" styleName="related-link-item">
                                <div>
                                    <div styleName="related-link-content-container"
                                         style={{backgroundImage: `url(${props.right.image})`}}
                                    >
                                        <Link styleName="related-link-content" to={props.right.link}>
                                            <h4 styleName="related-link-label">Community</h4>
                                        </Link>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

RelatedLinks.propTypes = {
    left: PropTypes.shape({
        image: PropTypes.string,
        link: PropTypes.string,
        label: PropTypes.string,
    }),
    center: PropTypes.shape({
        image: PropTypes.string,
        link: PropTypes.string,
        label: PropTypes.string,
    }),
    right: PropTypes.shape({
        image: PropTypes.string,
        link: PropTypes.string,
        label: PropTypes.string,
    }),
    position: PropTypes.oneOf(['left', 'right']),
    marginTop: PropTypes.bool,
}

RelatedLinks.defaultProps = {
    left: {
        image: contactImage,
        link: '/about/contact/',
        label: 'Contact',
    },
    center: {
        image: aboutImage,
        link: '/about/',
        label: 'About AANZP',
    },
    right: {
        image: communityImage,
        link: '/about/community/',
        label: 'Community',
    },
    position: 'left',
    marginTop: true
}

export default withStyles(styles)(RelatedLinks);