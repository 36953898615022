import React, {Component, Fragment} from 'react';
import { connect } from 'react-redux';
import {Picture} from 'react-responsive-picture';
import {convertFromRaw} from 'draft-js';

import {getEvents, getEvent} from '../data/events/actions'


const eventEnhancer = (WrappedComponent) => {
    class Event extends Component {
        constructor(props) {
            super(props);

            this.getSingleEvent = this._getSingleEvent.bind(this);
            this.pageUrl = this.props.location
                ? `https://www.aanzp.com.au${this.props.location.pathname}`
                : 'https://www.aanzp.com.au';
        }

        _getSingleEvent(slug='') {
            const eventSlug = slug ? slug : this.props.match.params.eventSlug;

            this.props.getEvent(eventSlug, true);
        }

        getImage(event, size, returnObject=true) {
            const image = event ? event.featuredImage : false;

            if (image) {
                const imageItem = image.sizes.find(image => {
                    return image.size === size;
                });

                return returnObject ? imageItem : imageItem.url;
            }

            return returnObject ? {} : '';
        }

        getFeaturedImage(event, sizes) {
            const featuredImage = event ? event.featuredImage : false;

            if (featuredImage) {
                const sources = featuredImage.sizes.filter(image => {
                    return image.size.startsWith('card');
                }).map(image => (`${image.url} ${image.width}w`));

                const imgSrc = sources.join(', ');

                return <Picture src={imgSrc} alt={featuredImage.alt} sizes={sizes}/>
            }
        }

        getExcerpt(event) {
            if (event.content) {
                const contentState = convertFromRaw(JSON.parse(event.content));
                const plainText = contentState.getPlainText('').replace(/(\r\n|\n|\r)/gm, " ");;

                let trimmed = plainText.substring(0, 130);
                trimmed = trimmed.substr(0, Math.min(trimmed.length, trimmed.lastIndexOf(" ")));

                return `${trimmed}...`;
            }
        }

        getLink(event) {
            return `/events/${event.slug}/`;
        }

        render() {
            return (
                <WrappedComponent
                    singleEvent={this.props.event}
                    events={this.props.events}
                    getEvent={this.getSingleEvent}
                    getEvents={this.props.getEvents}
                    getFeaturedImage={this.getFeaturedImage}
                    getImage={this.getImage}
                    getExcerpt={this.getExcerpt}
                    getLink={this.getLink}
                    pageUrl={this.pageUrl}
                />
            )
        }
    }

    return connect(mapStateToProps, mapDispatchToProps)(Event);
};

const mapStateToProps = (state, ownProps) => {
    const {data: {events: {currentEvent: event, events} } } = state;

    return {
        event,
        events
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getEvents: (page=1, filters={}) => dispatch(getEvents(page, filters)),
        getEvent: (eventId, useSlug=false) => dispatch(getEvent(eventId, useSlug)),
    }
}

export const loadSingleEvent = (slug='') => {
    return (store, pathKeys, matchPath) => {
        const eventSlug = slug ? slug : pathKeys.eventSlug;

        return store.dispatch(getEvent(eventSlug, true));
    }
}

export const loadEvents = (page=1, filters={}) => {
    return (store) => {
        return store.dispatch(getEvents(page, filters));
    }
}

export default eventEnhancer;