import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from 'react-router-dom';
import CSSModules from 'react-css-modules';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import styles from './styles.scss';


const SecondaryNav = () => {
    return (
        <div styleName="secondary-nav">
            <div styleName="secondary-nav__container">
                <ul styleName="social-links" className="list-inline">
                    <li className="list-inline-item">
                        <a href="https://www.facebook.com/prosthodontists/" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={['fab', 'facebook']}/>
                        </a>
                    </li>
                    <li className="list-inline-item">
                        <a href="https://twitter.com/TheAANZP/" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={['fab', 'twitter']}/>
                        </a>
                    </li>
                </ul>
                <ul styleName="member-links" className="list-inline">
                    {/*<li className="list-inline-item">*/}
                        {/*<Link to="/membership">*/}
                            {/*Become a Member*/}
                        {/*</Link>*/}
                    {/*</li>*/}
                    <li className="list-inline-item">
                        <a href="https://members.aanzp.com.au" target="_blank" rel="noopener noreferrer">
                            Member Login <FontAwesomeIcon icon={['far', 'sign-in']}/>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    );
}

// export default withStyles(styles)(CSSModules(SecondaryNav, styles));
export default withStyles(styles)(SecondaryNav);