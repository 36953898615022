import React, {Component, Fragment} from 'react';
import {Link} from 'react-router-dom';
import withStyles from "isomorphic-style-loader/lib/withStyles";
import {TwitterTimelineEmbed} from 'react-twitter-embed';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import withPageSubscription, {loadData} from '../withPageSubscription';
import Section from '../../components/Section';
import FeedItems from './components/FeedItems';
import styles from './styles.scss';

class News extends Component {
    render() {
        const {page} = this.props;

        return (
            <Fragment>
                {this.props.getHero(page.title, 0.6)}
                <Section
                    styleName="news"
                    tag="section"
                    wrap={false}
                    isTop={true}
                    isBottom={true}
                >
                    <div styleName="news__feed">
                        <FeedItems/>
                    </div>
                    <div styleName="news__sidebar">
                        <div styleName="news__twitter">
                            <h4>
                                AANZP Latest <FontAwesomeIcon icon={['fab', 'twitter']}/>
                            </h4>
                            <div>
                                <TwitterTimelineEmbed
                                    sourceType="profile"
                                    screenName="TheAANZP"
                                    autoHeight
                                    noHeader
                                    noFooter
                                    transparent
                                    theme="dark"
                                    linkColor="#ffffff"
                                />
                            </div>
                        </div>
                        <Link styleName="events-cta" to="/events">
                            Upcoming Events <FontAwesomeIcon icon={['far', 'long-arrow-right']}/>
                        </Link>
                    </div>
                </Section>
            </Fragment>
        )
    }
}

export default {
    loadData: loadData(),
    component: withPageSubscription(withStyles(styles)(News))
}

