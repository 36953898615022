import { library } from '@fortawesome/fontawesome-svg-core';
import { faFacebook, faInstagram, faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons';

import {
	faGraduationCap
} from '@fortawesome/pro-light-svg-icons';

import {
	faBars,
	faSignIn,
	faLongArrowRight,
	faCalendarAlt,
	faCalendar,
	faNewspaper,
	faMapMarkerAlt as faMapMarkerAltReg,
	faPhone,
	faGlobe,
	faEnvelope,
	faMicroscope,
	faCircleNotch
} from '@fortawesome/pro-regular-svg-icons';

import {
	faUser,
	faUniversity,
	faMapMarkerAlt,
	faCaretDown,
	faEnvelope as faEnvelopeSolid,
	faCalendar as faCalendarSolid,
	faUsers,
	faShare,
	faLongArrowRight as faLongArrowRightSolid,
	faCalendarDay,
	faTooth,
	faSearch,
	faUserGraduate
} from '@fortawesome/pro-solid-svg-icons';

export default library.add(
	faBars,
	faFacebook,
	faInstagram,
	faTwitter,
	faSignIn,
	faLongArrowRight,
	faCalendarAlt,
	faCalendar,
	faCalendarSolid,
	faNewspaper,
	faUser,
	faUniversity,
	faGraduationCap,
	faMapMarkerAlt,
	faMapMarkerAltReg,
	faPhone,
	faGlobe,
	faEnvelope,
	faEnvelopeSolid,
	faUsers,
	faCaretDown,
	faLinkedin,
	faShare,
	faLongArrowRightSolid,
	faMicroscope,
	faTooth,
	faSearch,
	faCalendarDay,
	faUserGraduate,
	faCircleNotch
);


