import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import withStyles from 'isomorphic-style-loader/lib/withStyles';
import {
    Card,
    CardBody,
    CardTitle,
    CardText,
    CardLink
} from 'reactstrap';


import styles from './styles.scss';


const CardStandard = (props) => {
    return (
        <Card styleName={`standard-card ${props.unstyled ? 'unstyled' : ''}`}>
            {
                props.link
                    ? <Link to={props.link} styleName="standard-card__image">{props.img}</Link>
                    : <div styleName="standard-card__image">{props.img}</div>
            }
            <CardBody styleName="standard-card__body">
                <CardTitle styleName="standard-card__title">{props.title}</CardTitle>
                {props.subtitle && <h6>{props.subtitle}</h6>}
                <CardText>
                    {props.children}
                </CardText>
                {props.link && props.showMore &&
                    <CardLink tag="div">
                        <Link to={props.link}>
                              More <FontAwesomeIcon icon={['far', 'long-arrow-right']}/>
                        </Link>
                    </CardLink>
                }
            </CardBody>
        </Card>
    );
};



CardStandard.propType = {
    img: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    link: PropTypes.string,
    unstyled: PropTypes.bool,
    showMore: PropTypes.bool
}


CardStandard.defaultProps = {
    img: '',
    title: '',
    subtitle: '',
    link: '',
    unstyled: false,
    showMore: true
}

export default withStyles(styles)(CardStandard);