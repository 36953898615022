import React from 'react';
import withStyles from "isomorphic-style-loader/lib/withStyles";
import {NavLink} from 'react-router-dom';
import PropTypes from 'prop-types';

import styles from './styles.scss';

const SubNavigation = (props) => {
    const renderNavItems = () => {
        return props.navigationItems.map((item) => (
            <li key={item.id} styleName="nav-item">
                {props.filterContent
                    ? <a
                        styleName="nav-item-link"
                        className={item.active ? 'active' : ''}
                        onClick={() => props.filterContent(item.slug)}
                    >{item.title}</a>
                    : <NavLink to={item.link} styleName="nav-item-link" exact>{item.title}</NavLink>
                }
            </li>
        ));
    }
    return (
        <ul styleName={`nav nav-${props.position}`} className='list-unstyled'>
            {renderNavItems()}
        </ul>
    )
};

SubNavigation.propTypes = {
    navigationItems: PropTypes.arrayOf(PropTypes.object),
    position: PropTypes.oneOf(['left', 'right']),
    filterContent: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.func
    ])
}

SubNavigation.defaultProps = {
    navigationItems: [],
    position: 'left',
    filterContent: false
}

export default withStyles(styles)(SubNavigation);