import React, {useEffect} from 'react';
import withStyles from "isomorphic-style-loader/lib/withStyles";

import DraftToHtml from '../../../../components/DraftToHtml';
import styles from './styles.scss';
import {Col} from "reactstrap";
import postEnhancer from "../../../../posts/postEnhancer";
import CardStandard from "../../../../components/CardStandard";


const CommonDentalProcedures = (props) => {
    const {
        page,
        preludeAfter,
        getPosts,
        getPostFeaturedImage,
        getLink,
        getExcerpt,
        posts
    } = props;

    useEffect(() => {
        getPosts('common-procedures', 1, {page_size: 50});
    }, []);


    const renderProcedures = () => {
        const { types } = posts;
        const { 'common-procedures': commonProcedures } = types;

        if (commonProcedures) {
            const imageSizes = `(min-width: 1200px) 222px,
            (min-width: 992px) 288px, 
            (min-width: 768px) 238px, 
            (min-width: 576px) 238px ,100vw`;

            return commonProcedures.data.map((procedure) => {
                return (
                    <div key={procedure.id} styleName="procedure">
                        <CardStandard
                            title={procedure.title}
                            img={ getPostFeaturedImage(procedure, imageSizes)}
                            link={ `/patient-resources/common-dental-procedures/${procedure.slug}` }
                        >
                            { getExcerpt(procedure) }
                        </CardStandard>
                    </div>
                );
            });
        }

        return null
    };

    return (
        <Col xs="12" md="9" lg="8">
            {page.prelude &&
                <p className="lead">
                    {page.prelude}
                </p>
            }
            {preludeAfter &&
            <div styleName="prelude-after">
                {preludeAfter}
            </div>}
            <DraftToHtml rawContentState={page.content}/>
            <div styleName="common-dental-procedures">
                { renderProcedures() }
            </div>
        </Col>
    );
}

export default postEnhancer(withStyles(styles)(CommonDentalProcedures));


