import React, {Component, Fragment, useEffect} from 'react';
import {Link} from 'react-router-dom';
import withStyles from "isomorphic-style-loader/lib/withStyles";
import {TwitterTimelineEmbed} from 'react-twitter-embed';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import universityEnhancer, {loadSingleUniversity} from '../../../universityEnhancer';
import Section from '../../../../components/Section';
import FeedItems from './components/FeedItems';
import styles from './styles.scss';
import {Helmet} from "react-helmet";
import {titleCase} from "../../../../utils";

const UniversityResearch = (props) => {
    const {
        singleUniversity,
        getUniversity,
        getHero,
        getResearch,
        pageUrl} = props;

    const getHelmet = () => {
        const pageTitle = singleUniversity.name;
        return (
            <Helmet>
                <title>{ `${titleCase(pageTitle)} - Research`  }</title>
            </Helmet>
        )
    };

    useEffect(() => {
        const { id } = singleUniversity;
        getUniversity();

        if (id) {
           getResearch(id, 20);
        }

    }, []);

    useEffect(() => {
        const { id } = props.singleUniversity;
        getResearch(id, 20);
    }, [props.singleUniversity.id]);

    return (
        <Fragment>
            {getHelmet()}
            {getHero(singleUniversity, singleUniversity.name, 0.6)}
            <Section
                styleName="news"
                tag="section"
                wrap={false}
                isTop={true}
                isBottom={true}
            >
                <div styleName="news__feed">
                    <FeedItems items={singleUniversity.research}/>
                </div>
                <div styleName="news__sidebar">
                    <div styleName="news__twitter">
                        <h4>
                            AANZP Latest <FontAwesomeIcon icon={['fab', 'twitter']}/>
                        </h4>
                        <div>
                            <TwitterTimelineEmbed
                                sourceType="profile"
                                screenName="TheAANZP"
                                autoHeight
                                noHeader
                                noFooter
                                transparent
                                theme="dark"
                                linkColor="#ffffff"
                            />
                        </div>
                    </div>
                    <Link styleName="events-cta" to="/events">
                        Upcoming Events <FontAwesomeIcon icon={['far', 'long-arrow-right']}/>
                    </Link>
                </div>
            </Section>
        </Fragment>
    )

};

const EnhancedUniversityResearch = universityEnhancer(withStyles(styles)(UniversityResearch));

export default {
    loadData: loadSingleUniversity(),
    component: EnhancedUniversityResearch
};

