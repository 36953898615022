import React, {Component, Fragment} from 'react';
import {withRouter} from "react-router-dom";
import { connect } from 'react-redux';
import * as yup from 'yup';
import withStyles from "isomorphic-style-loader/lib/withStyles";
import styles from "./styles.scss";

import withPageSubscription, {loadData} from "../withPageSubscription";
import DraftToHtml from '../../components/DraftToHtml';
import Section from "../../components/Section";
import Form from '../../components/Forms';
import { submitMembershipForm } from "../../data/forms/actions";


class BecomeAMember extends Component {
	constructor(props) {
		super(props);

		this.onSubmit = this._onSubmit.bind(this);
	}

	_onSubmit(values, setSubmitting) {

      	const data = {
      		title: values.title,
			first_name: values.firstName,
			last_name: values.lastName,
			email: values.email,
			member_type: values.memberType,
			password: values.password,
			profile: {
      			phone: values.phone,
				street: values.street,
				suburb: values.suburb,
				city_state: values.city,
				country: values.country,
				postcode: values.postcode,
				member_of_prosthodontist_society: values.memberOfPS.includes('on'),
				member_of_dental_association: values.memberOfDA.includes('on')
			}
		};

		this.props.submitForm('member-form', data, setSubmitting);
    };
	render() {
		const { page }  = this.props;

		const validationSchema = {
			title: yup.string().required('Field Required'),
			firstName: yup.string().required('Please provide your first name'),
			lastName: yup.string().required('Please provide your last name'),
			email: yup.string().email('Please enter a valid email').required('Please provide your email'),
			confirmEmail: yup.string()
				.oneOf([yup.ref('email'), null], 'Emails don\'t match')
				.required('Please confirm your email'),
			memberType: yup.mixed().required('Please select membership type').oneOf([
				'FM',
				'SM'
			], 'Please Select a valid membership type'),
			password: yup.string().required('Please Enter your password')
				.matches(
					/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})(?=.*[!@#\$%\^&\*])/,
      				"Your password must be at least 8 characters long and be a mixture of uppercase, lowercase, numbers & special characters"
    		),
			phone: yup.string().required('Please provide your phone number'),
			street: yup.string().required('Please provide your street address'),
			suburb: yup.string().required('Please provide your suburb'),
			city: yup.string().required('Please provide your city / state'),
			country: yup.string().required('Please provide your country'),
			postcode: yup.string().required('Please provide your postcode'),
			
		};

		return (
			<Fragment>
				{this.props.getHero(page.title, 0.6)}
				<Section wrap={true} isTop={true} isBottom={true}>
					<div styleName="main">
						<h1 styleName="main__title">{page.subtitle}</h1>
						<DraftToHtml rawContentState={page.content}/>
						<Form
							type="member-registration"
							onSubmit={this.onSubmit}
							validationSchema={validationSchema}
							submissionData={this.props.submissionData}
						/>
					</div>
				</Section>
			</Fragment>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
    const { data:  { forms } } = state;

    return {
		submissionData: forms.data
    }
};

const mapDispatchToProps = dispatch => {
    return {
		submitForm: (formName, data, setSubmitting) => dispatch(submitMembershipForm(formName, data, setSubmitting))
    }
};

const loadRequiredData = () => {
    return async (store, pathKeys, matchPath) => {
        const loadPageData = loadData();

        return await loadPageData(store, pathKeys, matchPath);
    }
};

const connectedBecomeAMember = connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(BecomeAMember));

export default {
    loadData: loadRequiredData(),
	component: withRouter(withPageSubscription(connectedBecomeAMember))
};