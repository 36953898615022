import {
    CREATE_NEW_POST,
    NETWORK_REQUEST_IN_PROGRESS,
    SET_CURRENT_POST,
    RESET_POST_DID_UPDATE,
    REQUEST_PAGINATED_POSTS,
    RECEIVE_PAGINATED_POSTS,
    RECEIVE_POST_SUGGESTIONS,
    CLEAR_POST_SUGGESTIONS,
    NETWORK_ERROR
} from './actions';


const initialState = {
    isUpdating: false,
    currentPost: {
        didUpdate: false,
        id: '',
        type: '',
        status: false,
        slug: '',
        created: false,
        lastUpdated: false,
        title: '',
        subtitle: '',
        content: '',
        featuredImage: false,
        universityName: '',
        meta: []
    },
    posts: {
        isRequesting: false,
        types: {}
    },
    autoSuggest: [{title: 'None', id: null}]

}

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_NEW_POST:
            return Object.assign({}, state, {
               ...state,
                isUpdating: action.isUpdating,
                currentPost: initialState.currentPost
            });
        case NETWORK_REQUEST_IN_PROGRESS:
            return Object.assign({}, state, {
                ...state,
                isUpdating: true
            });
        case SET_CURRENT_POST:
            return Object.assign({}, state, {
                ...state,
                isUpdating: false,
                currentPost: {
                    didUpdate: true,
                    id: action.id,
                    type: action.postType,
                    status: action.status,
                    slug: action.slug,
                    created: action.created,
                    lastUpdated: action.lastUpdated,
                    title: action.title,
                    subtitle: action.subtitle,
                    content: action.content,
                    featuredImage: action.featuredImage,
                    universityName: action.universityName,
                    meta: action.meta,
                },
            });
        case RESET_POST_DID_UPDATE:
            return Object.assign({}, state, {
               ...state,
               isUpdating: false,
               currentPost: {
                   ...state.currentPost,
                   didUpdate: false
               }
            });
        case REQUEST_PAGINATED_POSTS:
            return Object.assign({}, state, {
                ...state,
                posts: {
                    ...state.posts,
                    isRequesting: true,
                }
            });
        case RECEIVE_PAGINATED_POSTS:
            const { posts: { types } } = state;
            const updatedTypes = types;

            updatedTypes[action.postType] = {
                count: action.count,
                currentPage: action.currentPage,
                next: action.next,
                previous: action.previous,
                data: action.data
            };

            return Object.assign({}, state, {
                ...state,
                posts: {
                    isRequesting: false,
                    types: updatedTypes,
                }
            });
        case RECEIVE_POST_SUGGESTIONS:
            return Object.assign({}, state, {
                ...state,
                autoSuggest: action.results
            });
        case CLEAR_POST_SUGGESTIONS:
            return Object.assign({}, state, {
                ...state,
                autoSuggest: []
            });
        case NETWORK_ERROR:
            return Object.assign({}, state, {
                ...state,
                posts: {
                    ...state.posts,
                    isRequesting: false,
                }
            });
        default:
            return state
    }
}

