import React, {Component, Fragment} from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import Slider from "react-slick";

import postEnhancer from '../../../../posts/postEnhancer';
import styles from './styles.scss';
import CardStandard from "../../../../components/CardStandard";

const carouselSettings = {
    arrows: false,
    dots: false,
    slidesToShow: 3,
    infinite: false,
    responsive: [
        {
            breakpoint:  991,
            settings: {
                centerMode: true,
                slidesToShow: 1,
                centerPadding: '150px',
                infinite: true
            }
        },
        {
          breakpoint:  767,
          settings: {
              centerPadding: '50px',
              centerMode: true,
              slidesToShow: 1,
              infinite: true
          }
        },
        {
            breakpoint: 575,
            settings: {
                slidesToShow: 1,
                centerMode: true,
                centerPadding: '30px',
                infinite: true
            }
        }
    ]
}

class LatestNews extends Component {
    componentDidMount() {
        this.props.getPosts('news', 1, {page_size: 3})
    }

    renderNewsPosts() {
        const newsPosts = 'news' in this.props.posts.types ? this.props.posts.types.news.data : [];

        return newsPosts.slice(0, 3).map(post => {
            const imageSizes = `(min-width: 1200px) 338px,
            (min-width: 992px) 278px, 
            (min-width: 768px) 358px, 
            (min-width: 576px) 378px ,100vw`;

            return (
                <div key={post.id} styleName="news">
                    <CardStandard
                        title={post.title}
                        img={this.props.getPostFeaturedImage(post, imageSizes)}
                        link={this.props.getLink(post)}
                    >
                        {this.props.getExcerpt(post)}
                    </CardStandard>
                </div>
            );
        })
    }

    render() {
        return (
            <Slider {...carouselSettings}>
                {this.renderNewsPosts()}
            </Slider>
        )
    }
}

export default postEnhancer(withStyles(styles)(LatestNews));