import API from "./api";

export const SUBMIT_FORM = 'SUBMIT_FORM';
export const FORM_SUBMITTED = 'FORM_SUBMITTED';


const formSubmitting = (formName) => {
	return {
		type: SUBMIT_FORM,
		formName
	}
};

const formSubmitted = (data, formName) => {

	return {
		type: FORM_SUBMITTED,
		errors: data.errors ? data.errors : false,
		data: data.data ? data.data : false,
		formName
	}
}

const submitMembershipForm = (formName, data, setSubmitting) => {
	return async(dispatch, getState, api) => {
		const dataAPI = new API(api);

		dispatch(formSubmitting(formName));

		const json = await dataAPI.submitMemberRegistration(data);

		setSubmitting(false);

		return dispatch(formSubmitted(json, formName));
	}
};

const submitEnquiryForm = (formName, data, setSubmitting) => {
	return async(dispatch, getState, api) => {
		const dataAPI = new API(api);

		dispatch(formSubmitting(formName));

		const json = await dataAPI.submitEnquiry(data);

		setSubmitting(false);

		return dispatch(formSubmitted(json, formName));
	}
};

export { submitMembershipForm, submitEnquiryForm };