import React, {Fragment, useEffect} from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import {Helmet} from "react-helmet";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import moment from 'moment';
import {Row, Col} from 'reactstrap';
import {EmailShareButton, FacebookShareButton, LinkedinShareButton, TwitterShareButton} from "react-share";

import eventEnhancer, {loadSingleEvent} from '../eventEnhancer';
import styles from './styles.scss';
import Section from '../../components/Section';
import DraftToHtml from '../../components/DraftToHtml';



const EventSingle = (props) => {
    const {singleEvent, getEvent, getImage, pageUrl} = props;

    const eventDate = moment(singleEvent.date);
    const day = eventDate.format('DD');
    const month = eventDate.format('MMM');
    const year = eventDate.format('YYYY');
    const time = eventDate.format('hh:mm A');
    const location = singleEvent.location;

    useEffect(() => {
        getEvent();
    }, []);

    const getHelmet = () => {
        const pageTitle = singleEvent.title;
        return (
            <Helmet>
                <title>{pageTitle}</title>
            </Helmet>
        )
    }

    return (
        <Fragment>
            {getHelmet()}
            <Section styleName="event" tag="section" wrap={true} isTop={true}>
                <div styleName="event__header">
                    <div styleName="event__title">
                        <div className="date">
                            <div styleName="event__date-icon">
                                <FontAwesomeIcon icon={['far', 'calendar']}/>
                                <span>{day}</span>
                            </div>
                            <span>
                                {month}
                                <br/>
                                {year}
                            </span>
                        </div>
                        <div className="title">
                            <h1>{singleEvent.title}</h1>
                        </div>
                    </div>
                    <div styleName="event__image"
                         style={{backgroundImage: `url(${getImage(singleEvent, 'medium_large', false)})`}}>

                    </div>
                </div>
                <div styleName="event__body">
                    <Row>
                        <Col xs="12" md="9">
                            <h3 styleName="event__heading">
                                {singleEvent.universityName && <span>{singleEvent.universityName} - </span>} Event Details
                            </h3>
                            <DraftToHtml rawContentState={singleEvent.content}/>
                        </Col>
                        <Col styleName="event__details" xs="12" md="3">
                            <h3 styleName="event__heading">
                                <FontAwesomeIcon icon={['fas', 'calendar']}/> When
                            </h3>
                            <ul className='list-unstyled'>
                                <li>{time}</li>
                                <li>{eventDate.format('MMMM Do YYYY')}</li>
                            </ul>
                            <h3 styleName="event__heading">
                                <FontAwesomeIcon icon={['fas', 'map-marker-alt']}/> Where
                            </h3>
                            {location &&
                            <ul className="list-unstyled">
                                <li>{location.street}</li>
                                <li>{location.suburb}, {location.state}</li>
                                <li>{location.country} {location.postcode}</li>
                            </ul>
                            }
                            <h3 styleName="event__heading">
                                <FontAwesomeIcon icon={['fas', 'share']}/> Share
                            </h3>
                            <ul styleName="event__social-share" className="list-inline">
                                <li className="list-inline-item">
                                    <FacebookShareButton url={pageUrl}>
                                        <FontAwesomeIcon icon={['fab', 'facebook']}/>
                                    </FacebookShareButton>
                                </li>
                                <li className="list-inline-item">
                                    <TwitterShareButton url={pageUrl}>
                                        <FontAwesomeIcon icon={['fab', 'twitter']}/>
                                    </TwitterShareButton>
                                </li>
                                <li className="list-inline-item">
                                    <LinkedinShareButton url={pageUrl}>
                                        <FontAwesomeIcon icon={['fab', 'linkedin']}/>
                                    </LinkedinShareButton>
                                </li>
                                <li className="list-inline-item">
                                    <EmailShareButton url={pageUrl}>
                                        <FontAwesomeIcon icon={['far', 'envelope']}/>
                                    </EmailShareButton>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </div>
            </Section>
        </Fragment>
    )
};

const EnhancedEventSingle = eventEnhancer(withStyles(styles)(EventSingle));

export default {
    loadData: loadSingleEvent(),
    component: EnhancedEventSingle
};

