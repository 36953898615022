import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import curveSVG from './curve.svg';
import styles from './styles.scss';

const HeroCurve = (props) => {
    return (
        <div
            className={props.className}
            dangerouslySetInnerHTML={{ __html: curveSVG }}
            style={props.inlineStyle}
        />
    )
};

export default withStyles(styles)(HeroCurve);
