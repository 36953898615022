import React from 'react';
import withStyles from "isomorphic-style-loader/lib/withStyles";
import {Form, Field} from "formik";
import {Col, CustomInput, FormGroup, Input, Button, Row, Label} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import styles from '../styles.scss';

const displayName = 'Member Registration Form';

const initialValues = {
    title: '',
    firstName: '',
    lastName: '',
    email: '',
    confirmEmail: '',
    phone: '',
    street: '',
    suburb: '',
    city: '',
    country: '',
    postcode: '',
    memberOfDA: [],
    memberOfPS: [],
    memberType: '',
    password: ''
};

const CustomCheckbox = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, handleChange }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => (
    <CustomInput
        id={props.id}
        type="checkbox"
        name={field.name}
        label={props.label}
        onChange={field.onChange}
    />
);

const MemberRegistration = (props) => {
    const {
        values,
        touched,
        errors,
        handleBlur,
        setFieldValue,
        isSubmitting,
        submitForm,
        initialState,
        getPageSuggestions,
        clearPageSuggestions,
        pageSuggestions,
        submissionData
    } = props;

    const getFieldError = (fieldName) => {
        if (errors[fieldName] && touched[fieldName]) {
            return (
                <div className="invalid-feedback">
                    {errors[fieldName]}
                </div>
            );
        }

        return null;
    };

    const renderSubmissionErrors = () => {
        if (submissionData.errors && submissionData.formName === 'member-form') {
            const errorData = submissionData.errors.data;
            const errors = [];

            Object.entries(errorData).forEach(([key, value]) => {

               if (Array.isArray(value)) {
                   value.map((error) => {
                       errors.push(error);
                   });
               }

            });

            return errors.map((error, index) => {
                return (
                    <li key={index}>{error}</li>
                );
            });
        }

        return null;
    };

    if (submissionData.data && submissionData.formName === 'member-form') {
       return (
           <div>
               <h4>Thanks</h4>
               <p>Thanks for registering to be a member of the AANZP</p>
               <p>
                   We have sent you a welcome email and your application fee invoice (Full Members Only).
                   While we process your application you can login to the Member Dashboard at: <br/>
                   <a href="https://members.aanzp.com.au" target="_blank" rel="noopener noreferrer">
                       members.aanzp.com.au
                   </a>
               </p>
           </div>
       )
    } else {
        return (
            <Form styleName="form">
                <Row form>
                    <Col xs={12} lg={2}>
                        <FormGroup>
                            <Label htmlFor="title">
                                Title:*
                            </Label>
                            <Field
                                styleName="form__select"
                                className="custom-select"
                                id="title"
                                name="title"
                                as="select"
                            >
                                <option value="">Select</option>
                                <option value="DR">Dr</option>
                                <option value="MRS">Mrs</option>
                                <option value="MS">Ms</option>
                                <option value="MR">Mr</option>
                            </Field>
                            {getFieldError('title')}
                        </FormGroup>
                    </Col>
                    <Col xs={12} lg={5}>
                        <FormGroup>
                            <Label htmlFor="firstName">
                                First Name*
                            </Label>
                            <Field
                                styleName="form__input"
                                className="form-control"
                                name="firstName"
                                id="firstName"
                                placeholder="Your First Name"
                            />
                            {getFieldError('firstName')}
                        </FormGroup>
                    </Col>
                    <Col xs={12} lg={5}>
                        <FormGroup>
                            <Label htmlFor="lastName">
                                Last Name:*
                            </Label>
                            <Field
                                styleName="form__input"
                                className="form-control"
                                name="lastName"
                                id="lastName"
                                placeholder="Your Last Name"
                            />
                            {getFieldError('lastName')}
                        </FormGroup>
                    </Col>
                </Row>
                <FormGroup>
                    <Label htmlFor="email">
                        Email:*
                    </Label>
                    <Field
                        styleName="form__input"
                        className="form-control"
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Your personal Email, this will be your username"
                    />
                    {getFieldError('email')}
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="confirmEmail">
                        Confirm Email:*
                    </Label>
                    <Field
                        styleName="form__input"
                        className="form-control"
                        type="email"
                        name="confirmEmail"
                        id="confirmEmail"
                        placeholder="Please confirm your email"
                    />
                    {getFieldError('confirmEmail')}
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="phone">
                        Phone Number:*
                    </Label>
                    <Field
                        styleName="form__input"
                        className="form-control"
                        name="phone"
                        id="phone"
                        placeholder="Best number to get you on"
                    />
                    {getFieldError('phone')}
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="street">
                        Street*
                    </Label>
                    <Field
                        styleName="form__input"
                        className="form-control"
                        name="street"
                        id="street"
                        placeholder="Your Street Address"
                    />
                    {getFieldError('street')}
                </FormGroup>
                <Row form>
                    <Col lg={6}>
                        <FormGroup>
                            <Label htmlFor="suburb">
                                Suburb*
                            </Label>
                            <Field
                                styleName="form__input"
                                className="form-control"
                                name="suburb"
                                id="suburb"
                                placeholder="Your Suburb"
                            />
                        </FormGroup>
                        {getFieldError('suburb')}
                    </Col>
                    <Col lg={6}>
                        <FormGroup>
                            <Label htmlFor="city">
                                City / State*
                            </Label>
                            <Field
                                styleName="form__input"
                                className="form-control"
                                name="city"
                                id="city"
                                placeholder="Your City or State"
                            />
                        </FormGroup>
                        {getFieldError('city')}
                    </Col>
                </Row>
                <Row form>
                    <Col lg={6}>
                        <FormGroup>
                            <Label htmlFor="country">
                                Country*
                            </Label>
                            <Field
                                styleName="form__input"
                                className="form-control"
                                name="country"
                                id="country"
                                placeholder="Country"
                            />
                        </FormGroup>
                        {getFieldError('country')}
                    </Col>
                    <Col lg={6}>
                        <FormGroup>
                            <Label htmlFor="postcode">
                                Postcode*
                            </Label>
                            <Field
                                styleName="form__input"
                                className="form-control"
                                name="postcode"
                                id="postcode"
                                placeholder="Postcode"
                            />
                        </FormGroup>
                        {getFieldError('postcode')}
                    </Col>
                </Row>
                <FormGroup>
                    <Field
                        id="memberOfDA"
                        name="memberOfDA"
                        label="Are you a member of the ADA / NZDA?"
                        type="checkbox"
                        component={CustomCheckbox}
                    />
                    {getFieldError('memberOfDA')}
                </FormGroup>
                <FormGroup>
                    <Field
                        id="memberOfPS"
                        name="memberOfPS"
                        component={CustomCheckbox}
                        type="checkbox"
                        label="Are you a member of the APS / NZDAPS?"
                    />
                    {getFieldError('memberOfPS')}
                </FormGroup>
                <hr/>
                <FormGroup>
                    <Label htmlFor="membershipType">
                        Membership Type:*
                    </Label>
                    <Field
                        styleName="form__select"
                        className="custom-select"
                        id="membershipType"
                        name="memberType"
                        as="select"
                    >
                        <option value="" disabled>Select Membership Type</option>
                        <option value="FM">Full Member</option>
                        <option value="SM">Student Member</option>
                    </Field>
                    {getFieldError('membershipType')}
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="password">
                        Password*
                    </Label>
                    <Field
                        styleName="form__input"
                        className="form-control"
                        name="password"
                        id="password"
                        type="password"
                        placeholder="Enter your password"
                    />
                    {getFieldError('password')}
                    <small id="passwordHelpBlock" className="form-text text-muted">
                        Your password must be at least 8 characters long and be a mixture of uppercase,
                        lowercase, numbers & special characters
                    </small>
                </FormGroup>
                {submissionData.errors
                && <div className="alert alert-danger" role="alert">
                    <ul className="list-unstyled">{renderSubmissionErrors()}</ul>
                </div>
                }
                <Button type="submit" color="primary">
                    Submit
                    {isSubmitting
                    && <span> <FontAwesomeIcon icon={['far', 'circle-notch']}/></span>
                    }
                </Button>
            </Form>
        )
    }
};

export default withStyles(styles)(MemberRegistration);
export {displayName, initialValues}