import baseApi from '../api';

export default class statsAPI extends baseApi {
	async submitMemberRegistration(data) {

        const result = await this.fetchFromAPI(
            `members/`,
            'submit-member-registration',
            'Submit Member Registration',
            'post',
			data
        );

        return result;
    }

    async submitEnquiry(data) {

        const result = await this.fetchFromAPI(
            `enquiry/`,
            'submit-enquiry',
            'Submit Enquiry',
            'post',
			data
        );

        return result;
    }
}